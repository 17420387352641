// external
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Avatar,
  Box,
  Chip,
  Button,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Paper,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { ArrowDropDown, Edit, Delete, Add } from '@mui/icons-material';

// internal
import { useFunctions } from 'hooks/useFunctions';
import history from 'utils/history';
import firstLetterUppercase from 'utils/firstLetterUppercase';
import { getRoleName } from 'utils/getRoleName';

// components
import UserTextField from './fields/UserTextField';
import UserAutocompleteField from './fields/UserAutocompleteField';
import UserCountryList from './fields/UserCountryList';
import DeleteDialogBox from 'components/DialogBox/DeleteDialogBox';
import RelationshipDialogBox from 'components/DialogBox/RelationshipDialogBox';
import UserRelationshipsField from './fields/UserRelationshipsField';

// store
import { RootState } from 'store';
import {
  resetGuiderUser,
  setGuiderUser,
  deleteRelationship,
  IComm,
} from 'store/slices/guiderUserSlice';
import {
  setAppLoading,
  setAppError,
  setAppSuccess,
} from 'store/slices/appSlice';

const mapState = (state: RootState) => {
  return {
    guiderUser: state.guiderUser,
  };
};

const mapDispatch = {
  deleteRelationship,
  resetGuiderUser,
  setGuiderUser,
  setAppLoading,
  setAppError,
  setAppSuccess,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface IUserField {
  name: string;
  type: string;
  label: string;
  value: string | string[];
  validate: {
    alpha?: boolean;
    required?: boolean;
    regex?: string;
    max?: number;
    url?: boolean;
    email?: boolean;
  };
  editable: boolean;
  options?: { label?: string; text?: string; value: string }[];
}

export interface IProfileRelationship {
  otherParty: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    photo: string;
  };
  id: string;
}

interface IRelationshipDialogProps {
  role?: string;
  program?: string;
  oppositeRole?: string;
  name: string;
  email: string;
}

const UserDetails: React.FunctionComponent<PropsFromRedux> = ({
  guiderUser,
  resetGuiderUser,
  setGuiderUser,
  setAppLoading,
  setAppError,
  setAppSuccess,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [relationshipDialogOpen, setRelationshipDialogOpen] = useState(false);
  const [relationshipDialogProps, setRelationshipDialogProps] =
    useState<IRelationshipDialogProps>({
      name: ``,
      email: ``,
    });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<null | HTMLElement>(null);

  const { deleteGuiderUser, getGuiderUser, updateGuiderUserById } =
    useFunctions();

  const { guiderUserId } = useParams();

  useEffect(() => {
    if (
      guiderUserId &&
      (Object.keys(guiderUser).length === 0 || guiderUserId !== guiderUser.id)
    ) {
      setAppLoading({ show: true, size: 'small' });

      const findUser = async () => {
        return getGuiderUser(guiderUserId);
      };

      findUser()
        .then((user) => {
          setGuiderUser(user);
          setAppLoading({ show: false, size: 'small' });
        })
        .catch((error) => {
          setAppError(error.message);
          setAppLoading({ show: false, size: 'small' });
        });
    }

    return function cleanup() {
      resetGuiderUser();
    };
  }, [guiderUserId]);

  if (Object.keys(guiderUser).length === 0) return <></>;

  const {
    info,
    id,
    locales,
    updates,
    groups,
    metrics,
    profiles,
    photo,
    groupsListedIn,
    roles,
    relationships,
    availabilities,
    infoFields,
  } = guiderUser;

  const formattedUpdates: {
    [key: string]: string;
  } = {};

  let userIntegrations: string[] = [];
  let userManualAvailabilities: string[] = [];
  let userAvailability: string[] = [];

  if (guiderUser.auth && guiderUser) {
    userIntegrations = Object.keys(guiderUser.auth);
  }

  if (availabilities) {
    let convertedISODates = [];
    convertedISODates = availabilities?.map((item) => {
      return parseISO(item.startISO);
    });

    const convertDatesToString = convertedISODates.map((item) =>
      item.toString().slice(0, 24)
    );

    userManualAvailabilities = convertDatesToString;
  }

  if (userIntegrations.length !== 0) {
    userAvailability = [...userIntegrations];
  } else {
    userAvailability = [...userManualAvailabilities];
  }

  updates &&
    Object.entries(updates).forEach(([key, value]) => {
      if (typeof value === 'number') {
        Object.assign(formattedUpdates, {
          [key]: format(value, 'dd MMMM yyyy, kk:mm'),
        });
      } else if (value?.updated && typeof value.updated === 'number') {
        Object.assign(formattedUpdates, {
          [key]: format(value.updated, 'dd MMMM yyyy, kk:mm'),
        });
      }
    });

  //Handlers

  const goToUsers = () => {
    history.push('/users');
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchor(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsAnchor(null);
  };

  const handleRelationshipDialogOpen = (
    name: string,
    email: string,
    program?: string,
    role?: string,
    oppositeRole?: string
  ) => {
    setRelationshipDialogProps({
      role: role,
      program: program,
      oppositeRole: oppositeRole,
      name: name,
      email: email,
    });
    setRelationshipDialogOpen(true);
  };

  const handleRelationshipDialogClose = () => {
    setRelationshipDialogOpen(false);
    setTimeout(() => {
      setRelationshipDialogProps({
        name: '',
        email: '',
      });
    }, 1000);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      await deleteGuiderUser(id);
      setAppSuccess('User succesfully deleted');
      history.push('/users');
      handleDeleteDialogClose();
      resetGuiderUser();
    } catch (error) {
      let errorMessage =
        'Something went wrong, please try again later. If this issue persists please contact support';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setAppError(errorMessage);
    }
    setLoading(false);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    let isValid = true;

    const newInfo = { ...info };

    const { elements } = event.target as HTMLFormElement;
    console.log(elements);

    const infoFieldsIds = infoFields.map((infoField) => infoField.name);
    const fields = ['email', ...infoFieldsIds];

    fields.forEach((field: string) => {
      // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
      const formField = elements[field];
      console.log(field);
      let formValue = formField?.value;

      if (formField?.id?.includes('autocomplete-field')) {
        formValue = formField.parentNode.parentNode.getAttribute('data-value');
      }

      if (formValue !== '') {
        Object.assign(newInfo, { [field]: formValue });
      }

      formField &&
        formField.getAttribute('aria-invalid') === 'true' &&
        (isValid = false);
    });

    const newGroupsListedIn = { ...groupsListedIn };
    let newCoach;
    let newTrainee;

    const newProfiles = profiles?.map((profile, index) => {
      const isCoach = profile.coach ? true : false;

      if (isCoach) {
        const specialization = {};

        const skillsValue =
          // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
          elements[`skillsOffered-${index}`].parentNode.parentNode.getAttribute(
            'data-value'
          );
        const skillsArray = skillsValue.split(',');

        skillsArray.forEach((skill: string) => {
          Object.assign(specialization, { [skill]: true });
        });

        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        const description = elements[`description-${index}`].textContent;
        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        const offer = elements[`offer-${index}`].textContent;
        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        const professional = elements[`professional-${index}`].textContent;
        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        const personal = elements[`personal-${index}`].textContent;

        let groupStatus;
        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        if (elements[`status-${index}`].value === 'published') {
          groupStatus = true;
        } else {
          groupStatus = false;
        }

        const thisGroupListedIn = {
          [profile.group]: {
            coach: {
              listed: groupStatus,
            },
          },
        };

        Object.assign(newGroupsListedIn, thisGroupListedIn);

        if (locales.group === profile.group) {
          newCoach = {
            bio: {
              description,
              offer,
              personal,
              professional,
            },
            skills: {
              specialization,
            },
          };
        }

        return {
          group: profile.group,
          coach: {
            bio: {
              description,
              offer,
              personal,
              professional,
            },
            skills: {
              specialization,
            },
          },
        };
      } else {
        const specialization = {};

        const skillsValue =
          // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
          elements[`skillsSought-${index}`].parentNode.parentNode.getAttribute(
            'data-value'
          );
        const skillsArray = skillsValue.split(',');

        skillsArray.forEach((skill: string) => {
          Object.assign(specialization, { [skill]: true });
        });

        // @ts-expect-error: This is the result of poor type definitions of HTMLFormControlsCollection
        const bio = elements[`bio-${index}`].textContent;

        if (locales.group === profile.group) {
          newTrainee = {
            bio,
            skills_sought: {
              specialization,
            },
          };
        }

        return {
          group: profile.group,
          trainee: {
            bio,
            skills_sought: {
              specialization,
            },
          },
        };
      }
    });

    const updateObject = {
      info: newInfo,
    };

    newCoach && Object.assign(updateObject, { coach: newCoach });
    newTrainee && Object.assign(updateObject, { trainee: newTrainee });
    newProfiles && Object.assign(updateObject, { profiles: newProfiles });
    Object.keys(newGroupsListedIn).length !== 0 &&
      Object.assign(updateObject, { groupsListedIn: newGroupsListedIn });

    if (!isValid) {
      setAppError(`Please enter valid data.`);
    } else {
      setSaving(true);
      try {
        await updateGuiderUserById(id, updateObject);
        handleEditUserProfile();
        setAppSuccess('The user has been successfully updated!');
      } catch (error) {
        let errorMessage =
          'Something went wrong, please try again later. If this issue persists please contact support';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        setAppError(errorMessage);
      }

      setSaving(false);
    }
  };

  const getAuthenticationType = () => {
    if (id.includes('azure')) {
      return 'Azure';
    } else if (id.includes('okta')) {
      return 'Okta';
    }

    return 'Local account';
  };

  const handleEditUserProfile = () => {
    setEditing(!editing);
    handleActionsClose();
  };

  const handleCancelEditUser = () => {
    setLoading(true);

    setEditing(false);
    handleActionsClose();

    setTimeout(() => {
      setLoading(false);
    }, 1);
  };

  //User fields

  const createUserField = (
    name: string,
    type: string,
    label: string,
    value: string | string[] | IComm,
    validate: {
      alpha?: boolean;
      required?: boolean;
      regex?: string;
      max?: number;
      url?: boolean;
      email?: boolean;
    },
    editable: boolean,
    options?: { label: string; value: string }[]
  ): IUserField => {
    if (typeof value === 'string' || Array.isArray(value)) {
      return {
        name,
        type,
        label: firstLetterUppercase(label),
        value: value || '',
        validate,
        editable,
        options,
      };
    } else {
      return {
        name,
        type,
        label: firstLetterUppercase(label),
        value: '',
        validate,
        editable,
        options,
      };
    }
  };

  const userInfoFields = infoFields.map((field) => {
    let alternativeFieldValue;
    field.type === 'multiselect'
      ? (alternativeFieldValue = [])
      : (alternativeFieldValue = '');

    switch (field.name) {
      case 'firstName':
        return createUserField('', '', '', '', {}, false);
      case 'lastName':
        return createUserField('', '', '', '', {}, false);
      case 'jobRole':
        return createUserField(
          'jobRole',
          'text',
          'Job title',
          info?.jobRole || '',
          field.validate,
          true,
          field.options
        );
      case 'country':
        return createUserField(
          'country',
          'countryList',
          'Country',
          info?.country || '',
          {},
          true
        );
      case 'location':
        return createUserField(
          'location',
          'text',
          'Town or City',
          info?.location || '',
          field.validate,
          true,
          field.options
        );
      case 'linkedinUrl':
        return createUserField(
          'linkedinUrl',
          'text',
          'LinkedIn URL',
          info?.linkedinUrl || '',
          field.validate,
          true,
          field.options
        );
      default:
        return createUserField(
          field.name,
          field.type,
          field.label,
          info[field.name] || alternativeFieldValue,
          field.validate,
          true,
          field.options
        );
    }
  });

  const userDetailsFields = [
    {
      title: 'Profile',
      image: {
        alt: `${info?.firstName} ${info?.lastName}`,
        source: photo || '',
      },
      fields: [
        createUserField(
          'firstName',
          'text',
          'First name',
          info?.firstName || '',
          { required: true },
          true
        ),
        createUserField(
          'lastName',
          'text',
          'Last name',
          info?.lastName || '',
          { required: true },
          true
        ),
        createUserField(
          'email',
          'email',
          'Email address',
          info?.email || '',
          { email: true, required: true },
          true
        ),
        ...userInfoFields,
        createUserField(
          'commTools',
          'multiselect',
          'Communication preferences',
          info?.comms
            ? Object.values(info?.comms).map((comm) => comm.name)
            : [],
          {},
          false
        ),
        createUserField(
          'availability',
          'multiselect',
          'Availability',
          userAvailability,
          {},
          false
        ),
      ],
    },
    {
      title: 'Metadata',
      fields: [
        createUserField('userId', 'text', 'Account ID', id, {}, false),
        createUserField(
          'groups',
          'multiselect',
          'Programmes',
          groups && Object.keys(groups),
          {},
          false
        ),
        createUserField(
          'authType',
          'text',
          'Authenication type',
          getAuthenticationType(),
          {},
          false
        ),
        createUserField(
          'programsGroupID',
          'text',
          'Program groups',
          locales?.programsGroupId || '',
          {},
          false
        ),
        createUserField(
          'accountCreated',
          'text',
          'Registration date',
          formattedUpdates['account-created'] || '',
          {},
          false
        ),
        createUserField(
          'stakeholder',
          'text',
          'Stakeholder',
          roles?.stakeholder ? 'True' : 'False',
          {},
          false
        ),
        createUserField(
          'lastVisited',
          'text',
          'Last visited',
          formattedUpdates?.lastVisited || '',
          {},
          false
        ),
        createUserField(
          'metrics',
          'multiselect',
          'Metrics access',
          metrics || [],
          {},
          false
        ),
      ],
    },
  ];

  let traineeProfileIndex = 0;
  let coachProfileIndex = 0;

  const userProfilesFields = profiles?.map((profile, index) => {
    const { group, coach, trainee } = profile;
    const programType = groups[group].type;
    const role = coach ? 'guide' : 'trainee';
    const programSkills = guiderUser?.programsSkills?.[group];

    let roleName = role;
    let userSkills: string[] = [];
    let bio = '';
    let bios = {
      description: '',
      offer: '',
      personal: '',
      professional: '',
    };

    let profileStatus = '';
    let oppositeRoleName = '';

    if (role === 'guide') {
      oppositeRoleName = getRoleName(programType, 'trainee');
    } else {
      oppositeRoleName = getRoleName(programType, 'guide');
    }

    const profileRelationships = relationships
      ?.filter(
        (relationship) =>
          relationship.role === role &&
          relationship.relationshipGroupId === group
      )
      .map((relationship) => {
        return {
          otherParty: relationship.otherParty,
          id: relationship.relationshipId,
        };
      });

    switch (role) {
      case 'guide': {
        coachProfileIndex = coachProfileIndex + 1;
        if (
          groupsListedIn &&
          groupsListedIn[group] &&
          groupsListedIn[group].coach
        )
          profileStatus = groupsListedIn[group].coach?.listed
            ? 'published'
            : 'unlisted';

        if (coach && coach.skills) {
          userSkills = Object.keys(coach.skills.specialization);
        }

        if (coach && coach.bio) {
          bios = coach.bio;
        }

        roleName = getRoleName(programType, 'guide');

        return {
          title: `${firstLetterUppercase(
            roleName
          )} profile #${coachProfileIndex}`,
          programId: group,
          roleName: roleName,
          oppositeRoleName: oppositeRoleName,
          profileRelationships: profileRelationships,
          fields: [
            createUserField(
              `skillsOffered-${index}`,
              'multiselect',
              'Skills offered',
              userSkills,
              {},
              true,
              programSkills
            ),
            createUserField(
              `status-${index}`,
              'select',
              'Guide profile status',
              profileStatus,
              {},
              true,
              [
                { label: 'Unlisted', value: 'unlisted' },
                { label: 'Published', value: 'published' },
              ]
            ),
            createUserField(
              `description-${index}`,
              'textFieldMultiline',
              'Profile description',
              bios.description,
              {},
              true
            ),
            createUserField(
              `offer-${index}`,
              'textFieldMultiline',
              'Offer',
              bios.offer,
              {},
              true
            ),
            createUserField(
              `professional-${index}`,
              'textFieldMultiline',
              'Professional experiance',
              bios.professional,
              {},
              true
            ),
            createUserField(
              `personal-${index}`,
              'textFieldMultiline',
              'Personal information',
              bios.personal,
              {},
              true
            ),
          ],
        };
      }

      case 'trainee': {
        traineeProfileIndex = traineeProfileIndex + 1;
        if (trainee && trainee.skills_sought) {
          userSkills = Object.keys(trainee.skills_sought.specialization);
        }

        if (trainee && trainee.bio) {
          bio = trainee.bio;
        }

        roleName = getRoleName(programType, 'trainee');

        return {
          title: `${firstLetterUppercase(
            roleName
          )} profile #${traineeProfileIndex}`,
          programId: group,
          roleName: roleName,
          oppositeRoleName: oppositeRoleName,
          profileRelationships: profileRelationships,
          fields: [
            createUserField(
              `bio-${index}`,
              'textFieldMultiline',
              'Bio',
              bio,
              {},
              true
            ),
            createUserField(
              `skillsSought-${index}`,
              'multiselect',
              'Skills sought',
              userSkills,
              {},
              true,
              programSkills
            ),
          ],
        };
      }
    }
  });

  //Redering fields

  const renderFields = (
    item: {
      title: string;
      programId?: string;
      roleName?: string;
      oppositeRoleName?: string;
      image?: { alt: string; source: string };
      fields?: IUserField[];
      profileRelationships?: IProfileRelationship[];
    },
    index: number
  ) => {
    const { title, image, fields, programId, roleName, oppositeRoleName } =
      item;

    return (
      <Box key={index}>
        <Paper variant="outlined" sx={{ pb: 2, pl: 2, my: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
              <Typography
                color="inherit"
                variant="h5"
                component="h3"
                sx={{ fontWeight: 'bold' }}
              >
                {title}
              </Typography>

              {programId && (
                <Chip
                  label={programId}
                  size="small"
                  color="info"
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
            {editing && title.includes('profile') && (
              <Button
                variant="outlined"
                color="success"
                sx={{ m: 2 }}
                onClick={() =>
                  handleRelationshipDialogOpen(
                    `${guiderUser.info.firstName} ${guiderUser.info.lastName}`,
                    guiderUser.info.email,
                    programId,
                    roleName,
                    oppositeRoleName
                  )
                }
              >
                <Add sx={{ mr: 1 }} />
                {`Add ${oppositeRoleName}`}
              </Button>
            )}
          </Box>
          {image && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Avatar
                alt={image.alt}
                src={image.source}
                sx={{
                  width: 200,
                  height: 200,
                  mb: 3,
                  bgcolor: 'secondary.dark',
                }}
              >
                {(guiderUser.info &&
                  guiderUser.info.firstName &&
                  guiderUser.info.lastName &&
                  guiderUser.info.firstName.charAt(0) +
                    guiderUser.info.lastName.charAt(0)) ||
                  (guiderUser.info.email && guiderUser.info.email.charAt(0))}
              </Avatar>
              {editing && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    ml: 2,
                  }}
                >
                  {/* <Button variant="outlined" color="info">
                    <Upload />
                    Change photo
                  </Button>
                  <Button variant="outlined" color="error" sx={{ mt: 1 }}>
                    <Delete />
                    Remove photo
                  </Button> */}
                </Box>
              )}
            </Box>
          )}
          {fields && (
            <Grid container spacing={2} sx={{ width: '100%' }}>
              {fields.map((field, fieldIndex) => {
                switch (field.type) {
                  case 'text':
                  case 'email':
                    return (
                      <UserTextField
                        key={`${field.type}-${fieldIndex}`}
                        field={field}
                        isMultiline={false}
                        editing={editing}
                      />
                    );
                  case 'textFieldMultiline':
                    return (
                      <UserTextField
                        key={`${field.type}-${fieldIndex}`}
                        field={field}
                        isMultiline={true}
                        editing={editing}
                      />
                    );
                  case 'countryList':
                    return (
                      <UserCountryList
                        key={`${field.type}-${fieldIndex}`}
                        field={field}
                        editing={editing}
                      />
                    );
                  case 'multiselect':
                    return (
                      <UserAutocompleteField
                        key={`${field.type}-${fieldIndex}`}
                        field={field}
                        editing={editing}
                      />
                    );
                  case 'select':
                    return (
                      <UserTextField
                        isMultiline={false}
                        key={`${field.type}-${fieldIndex}`}
                        field={field}
                        editing={editing}
                      />
                    );
                }
              })}
            </Grid>
          )}
          {item.profileRelationships && (
            <UserRelationshipsField
              editing={editing}
              setEditing={setEditing}
              relationships={item.profileRelationships}
              oppositeRoleName={oppositeRoleName}
            />
          )}
        </Paper>
      </Box>
    );
  };

  const renderUserDetails = () =>
    userDetailsFields?.map((item, index) => renderFields(item, index));

  const renderUserProfiles = () =>
    userProfilesFields && (
      <>
        {userProfilesFields?.map(
          (item, index) => item && renderFields(item, index)
        )}
      </>
    );

  //Buttons

  const readOnlyButtons = (
    <>
      <Button
        variant="contained"
        color="info"
        sx={{ mx: 1 }}
        onClick={handleActionsClick}
      >
        Actions
        <ArrowDropDown sx={{ mr: -1 }} />
      </Button>

      <Menu
        open={Boolean(actionsAnchor)}
        anchorEl={actionsAnchor}
        onClose={handleActionsClose}
      >
        <MenuItem onClick={handleEditUserProfile} sx={{ pt: 0, pb: 0 }}>
          <Edit sx={{ ml: -1, mr: 1 }} />
          Edit
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteDialogOpen} sx={{ pt: 0, pb: 0 }}>
          <Delete sx={{ ml: -1, mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  const editButtons = (
    <>
      <Button
        variant="text"
        color="error"
        sx={{ mx: 1 }}
        onClick={() => {
          handleCancelEditUser();
        }}
        disabled={saving}
      >
        Discard Changes
      </Button>
      <LoadingButton
        variant="contained"
        sx={{ mx: 1 }}
        type="submit"
        loading={saving}
        color="info"
      >
        Save Changes
      </LoadingButton>
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Typography
            color="inherit"
            variant="h4"
            component="h2"
            sx={{ fontWeight: 'bold', mb: 1 }}
          >
            Users
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                onClick={goToUsers}
                sx={{ cursor: 'pointer' }}
                aria-label="users"
              >
                Users
              </Link>
              <Typography color="text.secondary" sx={{ opacity: 0.6 }}>
                {guiderUser.info.email}
              </Typography>
            </Breadcrumbs>
            <Box>{!editing ? readOnlyButtons : editButtons}</Box>
          </Box>
        </Box>
        {!loading && renderUserDetails()}
        {!loading && renderUserProfiles()}
      </form>
      <DeleteDialogBox
        open={deleteDialogOpen}
        loading={loading}
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDeleteUser}
        title="Are you sure?"
        description="The user cannot be restored after the deletion"
        deleteString="Delete User"
      />
      <RelationshipDialogBox
        open={relationshipDialogOpen}
        handleClose={handleRelationshipDialogClose}
        setLoading={() => setLoading}
        userEmail={relationshipDialogProps.email}
        userName={relationshipDialogProps.name}
        userRole={
          relationshipDialogProps.role &&
          firstLetterUppercase(relationshipDialogProps.role)
        }
        relationshipProgram={
          relationshipDialogProps?.program &&
          firstLetterUppercase(relationshipDialogProps?.program)
        }
        oppositeRoleName={relationshipDialogProps?.oppositeRole}
      />
    </Box>
  );
};

export default connector(UserDetails);
