// external
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Grid,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

// internal
import firstLetterUppercase from 'utils/firstLetterUppercase';

// store
import { RootState } from 'store';

// types
import { IUserField } from 'components/Users/UserDetails';

const mapState = (state: RootState) => {
  return {
    guiderUserRelationships: state.guiderUser.relationships,
    guiderUser: state.guiderUser,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IUserAutocompleteFieldProps extends PropsFromRedux {
  field: IUserField;
  editing: boolean;
}

const UserAutocompleteField: React.FunctionComponent<IUserAutocompleteFieldProps> =
  ({ field, editing }) => {
    const { value, label, name, editable, options } = field;

    const textFieldStyles = {
      label: {
        color: `#000000 !important`,
      },
    };

    let editableValues: string[];

    if (Array.isArray(value)) {
      editableValues = value;
    } else {
      editableValues = value.split(',');
    }

    const selectOptions =
      options?.map((option) => option.value) || editableValues;

    const [chipData, setChipData] = useState(editableValues);
    if (Array.isArray(chipData) && Array.isArray(selectOptions)) {
      const handleChipDelete = (index: number) => {
        const newChipData = chipData.filter(
          (_, chipIndex) => index !== chipIndex
        );
        setChipData(newChipData);
      };

      return (
        <Grid item xs={6}>
          {editing && editable ? (
            <Autocomplete
              disabled={!editing || !editable}
              multiple
              id={`${name}-autocomplete-field`}
              defaultValue={chipData}
              options={selectOptions}
              value={chipData}
              limitTags={2}
              disableCloseOnSelect
              onChange={(_, newValue) => {
                setChipData(newValue);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderTags={(tagValues, getTagProps) => {
                const numTags = tagValues.length;
                const limitTags = 2;

                return (
                  <>
                    {tagValues.slice(0, limitTags).map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option}
                        onDelete={() => handleChipDelete(index)}
                        data-testid="delete-chip"
                      />
                    ))}

                    {numTags > limitTags && ` +${numTags - limitTags}`}
                  </>
                );
              }}
              renderInput={(params) => (
                <TextField
                  name={name}
                  rows={1}
                  {...params}
                  variant="outlined"
                  label={label}
                  data-value={chipData.join(',')}
                  sx={textFieldStyles}
                />
              )}
            />
          ) : (
            <Box key={`${field.name}-field`}>
              <Box sx={{ ml: 2, mb: 1.5 }}>
                <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                  {label}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#0000008a',
                    overflowY: 'scroll',
                    height: '1.5rem',
                  }}
                >
                  {chipData.length > 0
                    ? chipData
                        .map((fieldValue) => firstLetterUppercase(fieldValue))
                        .join(', ')
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
            </Box>
          )}
        </Grid>
      );
    }

    return <></>;
  };

export default connector(UserAutocompleteField);
