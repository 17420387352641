import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const sanity = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  apiVersion: '2022-02-14',
  useCdn: true,
});

export const imageBuilder = imageUrlBuilder(sanity);

export * from './utils';

export default sanity;
