// external
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { Mail, Warning } from '@mui/icons-material';

interface IGenericErrorProps {
  code: string;
  message: string;
}

export const GenericError: React.FC<IGenericErrorProps> = ({
  code,
  message,
}) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        pb: '100px',
        position: 'relative',
        boxSizing: 'border-box',
        backgroundColor: '#fafafa',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          maxWidth: '450px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          flexGrow: 1,
        }}
      >
        <Warning
          sx={{
            color: '#EA0000',
            height: '51px',
            width: '58px',
            marginBottom: 3,
          }}
        />
        <Typography variant="h4" component="h1" sx={{ fontSize: '34px' }}>
          A critical error has occurred
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: 1.5, marginBottom: 3 }}
        >
          This is embarrassing, something went wrong on our end. Please try
          again in a few minutes.
        </Typography>
        <Alert severity="info" sx={{ textAlign: 'left' }}>
          <AlertTitle>Error: {code}</AlertTitle>
          {message}
        </Alert>
        <Divider />
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: 3, marginBottom: 3 }}
        >
          If this issue persists please contact support using the button below.
        </Typography>
        <Button
          href={`mailto:support@guider.app?subject=Error while using the Guider Application&body=Hi,\r\rI experienced the following error while trying to use the Guider platform at ${window.location.href}:\r\rError Code: ${code}\rError Message: ${message}\r\rThanks in advance for your help!`}
          variant="outlined"
          color="info"
          startIcon={<Mail />}
          size="small"
        >
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default GenericError;
