//external
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CssBaseline, Box, Snackbar, Alert } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

//internal
import history from 'utils/history';
import { useFunctions } from 'hooks/useFunctions';

//components
import Login from 'components/Login/Login';
import Loading from 'components/Loading/Loading';
import Navigator from 'components/Navigator/Navigator';
import Header from 'components/Header/Header';
import CustomRoutes from 'components/CustomRoutes/CustomRoutes';

//store
import { RootState } from 'store';
import { setUser, setUserError } from 'store/slices/userSlice';
import {
  setAppLoading,
  hideAppError,
  hideAppSuccess,
} from 'store/slices/appSlice';

const mapState = (state: RootState) => {
  return {
    appLoading: state.app.loading,
    appUserLoaded: state.user.loaded,
    appErrorMessage: state.app.error.message,
    appErrorShow: state.app.error.show,
    appSuccessMessage: state.app.success.message,
    appSuccessShow: state.app.success.show,
    userError: state.user.error,
  };
};

const mapDispatch = {
  setUser,
  setUserError,
  setAppLoading,
  hideAppError,
  hideAppSuccess,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AppContainer: React.FunctionComponent<PropsFromRedux> = ({
  setUser: setUserAction,
  appUserLoaded,
  setAppLoading,
  setUserError,
  userError,
  appErrorMessage,
  appErrorShow,
  hideAppError,
  appSuccessMessage,
  appSuccessShow,
  hideAppSuccess,
}) => {
  const {
    user: authUser,
    isAuthenticated,
    isLoading: isAuthLoading,
  } = useAuth0();
  const { getUser } = useFunctions();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isDesktop = useMediaQuery('(min-width:600px)');
  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getAppUser = async () => {
    if (!authUser) {
      setAppLoading({ show: false });
      return;
    }

    try {
      const user = await getUser();
      setUserAction(user);
    } catch (error) {
      history.push('/');
      setUserError(error);
    }

    setAppLoading({ show: false });
  };

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      history.push('/');
      setAppLoading({ show: false });
    }

    if (
      isAuthenticated &&
      authUser &&
      authUser.sub &&
      !appUserLoaded &&
      !userError
    ) {
      getAppUser();
    }

    if (isAuthenticated && appUserLoaded) {
      const currentPath = history.location.pathname;
      currentPath === '/' && history.push('/programs');

      setAppLoading({ show: false });
    }
  }, [isAuthLoading, appUserLoaded]);

  const renderNavigator = () =>
    isDesktop ? (
      <Navigator />
    ) : (
      <Navigator
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        open={mobileOpen}
        handleMobileDrawerToggle={handleMobileDrawerToggle}
      />
    );

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          height: '100%',
          margin: 0,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        }}
      >
        <HistoryRouter history={history}>
          {isAuthenticated && appUserLoaded ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              {renderNavigator()}

              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Header handleMobileDrawerToggle={handleMobileDrawerToggle} />
                <Box
                  sx={{
                    py: 6,
                    px: 4,
                    bgcolor: '#fafafa',
                    display: 'grid',
                    minHeight: 'calc(100vh - 64px)',
                  }}
                >
                  <CustomRoutes />
                </Box>
              </Box>
            </Box>
          ) : (
            <Login />
          )}
        </HistoryRouter>
        <Loading />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={appErrorShow}
          autoHideDuration={6000}
          onClose={hideAppError}
        >
          <Alert onClose={hideAppError} severity="error">
            {appErrorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={appSuccessShow}
          autoHideDuration={6000}
          onClose={hideAppSuccess}
        >
          <Alert onClose={hideAppSuccess} severity="success">
            {appSuccessMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default connector(AppContainer);
