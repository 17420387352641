// external
import { Routes, Route } from 'react-router-dom';

// components
import Users from 'components/Users/Users';
import ProgramList from 'components/Program/ProgramList';
import Program from 'components/Program/Program';
import ProgramDetails from 'components/Program/ProgramDetails';
import UserSearch from 'components/Users/UserSearch';
import UserDetails from 'components/Users/UserDetails';
import RelationshipsSearch from 'components/Relationships/RelationshipsSearch';
import Relationships from 'components/Relationships/Relationships';
import RelationshipsList from 'components/Relationships/RelationshipsList';
import RelationshipDetails from 'components/Relationships/RelationshipDetails';
import Metrics from 'components/Metrics/Metrics';

export const CustomRoutes: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/users" element={<Users />}>
        <Route path="" element={<UserSearch />} />
        <Route path=":guiderUserId" element={<UserDetails />} />
      </Route>
      <Route path="/programs" element={<Program />}>
        <Route path="" element={<ProgramList />} />
        <Route path=":programId" element={<ProgramDetails />} />
      </Route>
      <Route path="/relationships" element={<Relationships />}>
        <Route path="" element={<RelationshipsSearch />} />
        <Route path=":relationshipId" element={<RelationshipDetails />} />
        <Route path="user/:guiderUserId" element={<RelationshipsList />} />
      </Route>
      <Route path="/metrics" element={<Metrics />} />
    </Routes>
  );
};

export default CustomRoutes;
