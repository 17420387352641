//external
import { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Typography, Breadcrumbs, Alert, Box } from '@mui/material';

//internal
import { useFunctions } from 'hooks/useFunctions';

//store
import { RootState } from 'store';
import { setAppLoading, setAppError } from 'store/slices/appSlice';

const mapState = (state: RootState) => {
  return {
    app: state.app,
  };
};

const mapDispatch = {
  setAppLoading,
  setAppError,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Metrics: React.FunctionComponent<PropsFromRedux> = ({
  setAppLoading,
  setAppError,
}) => {
  const [holisticsToken, setHolisticsToken] = useState('');

  const { getHolisticsToken } = useFunctions();
  const getMetrics = async () => {
    return getHolisticsToken();
  };

  useEffect(() => {
    setAppLoading({ show: true, size: 'small' });

    getMetrics()
      .then((token) => {
        setHolisticsToken(token);
      })
      .catch((error) => {
        setAppError(error.message);
        setAppLoading({ show: false, size: 'small' });
      });
  }, []);

  const dashboardEmbedCode =
    process.env.REACT_APP_HOLISTICS_DASHBOARD_EMBED_CODE;

  const dashboardUrl = useMemo(
    () =>
      `https://secure.holistics.io/embed/${dashboardEmbedCode}?_token=${holisticsToken}`,
    [holisticsToken]
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        color="inherit"
        variant="h4"
        component="h2"
        sx={{ fontWeight: 'bold', mb: 1 }}
      >
        Metrics
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary">Metrics</Typography>
      </Breadcrumbs>
      <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
        Metrics are updated every hour.
      </Alert>
      {holisticsToken && (
        <iframe
          title="Metrics"
          src={dashboardUrl}
          frameBorder="0"
          style={{ border: 0, width: '100%', flex: 1 }}
          allowFullScreen
          onLoad={() => setAppLoading({ show: false, size: 'small' })}
        ></iframe>
      )}
    </Box>
  );
};

export default connector(Metrics);
