// external
import { useState } from 'react';
import {
  Box,
  FormControl,
  TextField,
  Typography,
  Paper,
  Container,
  Breadcrumbs,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { connect, ConnectedProps } from 'react-redux';

// internal
import { useFunctions } from 'hooks/useFunctions';
import history from 'utils/history';

// store
import { setAppError } from 'store/slices/appSlice';
import { setRelationships } from 'store/slices/relationshipsSlice';

const mapState = () => {
  return {};
};

const mapDispatch = {
  setRelationships,
  setAppError,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const RelationshipsSearch: React.FunctionComponent<PropsFromRedux> = ({
  setRelationships,
  setAppError,
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { getRelationshipsByUser } = useFunctions();

  const findRelationships = async () => {
    setLoading(true);
    if (email.includes('@') && email.includes('.')) {
      try {
        const guiderUserRelationships = await getRelationshipsByUser(
          email.toLowerCase().trim()
        );

        setRelationships(guiderUserRelationships);

        if (guiderUserRelationships.list.length === 0) {
          setAppError(
            `The email ${guiderUserRelationships.guiderUserInfo.email} does not have any relationships`
          );
        }

        history.push(
          `/relationships/user/${guiderUserRelationships.guiderUserInfo.id}`
        );
      } catch (error) {
        let errorMessage = `The email ${email} does not exist in the userbase.`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }

        setAppError(errorMessage);
      }
    } else {
      setAppError('Please enter a valid email address');
    }
    setLoading(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <FormControl sx={{ width: '100%' }}>
          <Typography
            color="inherit"
            variant="h4"
            component="h2"
            sx={{ fontWeight: 'bold', mb: 1 }}
          >
            Relationships
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.secondary">Relationships</Typography>
          </Breadcrumbs>
          <Paper variant="outlined" sx={{ mt: 2 }}>
            <Container sx={{ m: 1 }}>
              <Typography
                color="inherit"
                variant="h6"
                component="p"
                sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}
              >
                {"View user's relationships"}
              </Typography>
              <Typography color="inherit" variant="body1">
                Enter a users email address below to view and manage their
                relationships.
              </Typography>

              <TextField
                id="outlined-basic"
                label="Email address"
                variant="outlined"
                placeholder="Enter user's email address"
                sx={{ mt: 3, mb: 3, width: '50%' }}
                value={email}
                disabled={loading}
                onChange={(event) => setEmail(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    findRelationships();
                  }
                }}
              />
              <LoadingButton
                variant="contained"
                size="large"
                sx={{ display: 'flex', mb: 2 }}
                onClick={findRelationships}
                color="info"
                disabled={!email}
                loading={loading}
              >
                <Search sx={{ mr: 1 }} />
                Lookup user
              </LoadingButton>
            </Container>
          </Paper>
        </FormControl>
      </Box>
    </>
  );
};

export default connector(RelationshipsSearch);
