export interface IFirebaseError {
  message?: string;
  details?: {
    message: string;
  };
}

export const getFirebaseErrorMessage: (
  error: IFirebaseError
) => string | undefined = (error) => {
  if (error.message) {
    return error.message;
  }
  return error?.details?.message;
};
