// external
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Typography,
  Link,
  Chip,
  AlertTitle,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

// assets
import guiderAdminLogo from '../../assets/images/logo-guider-admin.svg';

// store
import { RootState } from 'store';
import { setAppLoading } from 'store/slices/appSlice';

const { REACT_APP_VERSION } = process.env;

const mapState = (state: RootState) => {
  return {
    appLoading: state.app.loading,
    appUser: state.user,
    userError: state.user.error,
  };
};

const mapDispatch = {
  setAppLoading,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Login: React.FunctionComponent<PropsFromRedux> = ({ userError }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    loginWithRedirect();
  };
  const handleSignUp = async () => {
    loginWithRedirect({ screen_hint: 'signup' });
  };

  const renderContent = () => {
    if (userError === 'User has no role.') {
      return (
        <Alert
          severity="success"
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: '90%',
            margin: 'auto',
            mt: 3,
            mb: 1.5,
          }}
        >
          <AlertTitle>Registration succesful</AlertTitle>
          <Typography variant="body2">
            Your account has been successfully created. Contact your customer
            success manager so they can activate it.
          </Typography>
        </Alert>
      );
    } else if (userError) {
      return (
        <Alert
          severity="error"
          sx={{
            mb: 3,
            width: '90%',
            textAlign: 'center',
            margin: 'auto',
            my: 2,
          }}
        >
          {userError}
        </Alert>
      );
    } else {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 2,
          }}
        >
          <Typography sx={{ mt: 2 }}>
            Select an option below to continue
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="info"
            onClick={handleLogin}
            sx={{ mt: 2, width: '100%' }}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="info"
            onClick={handleSignUp}
            sx={{ my: 2, width: '100%' }}
          >
            Register
          </Button>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '100vh',
        background: '#403DAA',
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '450px',
          maxWidth: '90%',
          flexDirection: 'column',
          bgcolor: '#ffffff',
          boxShadow: 3,
          mt: '10vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F5F5F5',
          }}
        >
          <CardMedia
            component="img"
            alt="Guider logo"
            src={guiderAdminLogo}
            sx={{ width: '60%', my: 4 }}
          />
        </Box>
        {renderContent()}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 2,
          }}
        >
          <Divider sx={{ my: 2, width: '100%' }} />
          <Typography sx={{ mb: 3, mt: 1 }}>
            Need help?{' '}
            <Link href="mailto:support@guider-ai.com">Contact support</Link>
          </Typography>
        </Box>
      </Card>
      <Box sx={{ color: '#ffffff', mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.8rem',
          }}
        >
          <Typography sx={{ mr: 1, fontSize: '0.8rem' }}>
            {REACT_APP_VERSION}
          </Typography>
          <Chip
            label="Beta"
            size="small"
            sx={{ fontSize: '0.8rem', backgroundColor: '#ffffff' }}
          />
        </Box>
        <Typography sx={{ fontSize: '0.8rem', textAlign: 'center', my: 1 }}>
          {'Copyright © '}
          {`${new Date().getFullYear()} `}
          <Link
            rel="noopener noreferrer"
            color="inherit"
            href="https://guider-ai.com/"
          >
            Guider Global Ltd.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default connector(Login);
