// external
import { useState } from 'react';
import {
  Box,
  FormControl,
  TextField,
  Typography,
  Paper,
  Container,
  Breadcrumbs,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { connect, ConnectedProps } from 'react-redux';

// internal
import { useFunctions } from 'hooks/useFunctions';
import history from 'utils/history';

// store
import { setAppError } from 'store/slices/appSlice';
import { setGuiderUser } from 'store/slices/guiderUserSlice';

const mapState = () => {
  return {};
};

const mapDispatch = {
  setGuiderUser,
  setAppError,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const UserSearch: React.FunctionComponent<PropsFromRedux> = ({
  setGuiderUser,
  setAppError,
}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { getGuiderUser } = useFunctions();

  const findUser = async () => {
    setLoading(true);
    if (email.includes('@') && email.includes('.')) {
      try {
        const user = await getGuiderUser(email.toLowerCase().trim());
        setGuiderUser(user);
        history.push(`/users/${user.id}`);
      } catch (error) {
        let errorMessage = `The email ${email} does not exist in the userbase.`;

        if (error instanceof Error) {
          errorMessage = error.message;
        }

        setAppError(errorMessage);
      }
    } else {
      setAppError('Please enter a valid email address');
    }
    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <FormControl sx={{ width: '100%' }}>
        <Typography
          color="inherit"
          variant="h4"
          component="h2"
          sx={{ fontWeight: 'bold', mb: 1 }}
        >
          Users
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.secondary">Users</Typography>
        </Breadcrumbs>
        <Paper variant="outlined" sx={{ mt: 2 }}>
          <Container sx={{ m: 1 }}>
            <Typography
              color="inherit"
              variant="h6"
              component="p"
              sx={{ fontWeight: 'bold', mt: 3 }}
            >
              Find a user by their email address
            </Typography>

            <TextField
              id="outlined-basic"
              label="Email address"
              variant="outlined"
              placeholder="Enter user's email address"
              sx={{ mt: 3, mb: 3, width: '50%' }}
              value={email}
              disabled={loading}
              onChange={(event) => setEmail(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  findUser();
                }
              }}
            />
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ display: 'flex', mb: 2 }}
              onClick={findUser}
              color="info"
              disabled={!email}
              loading={loading}
            >
              <Search sx={{ mr: 1 }} />
              Lookup user
            </LoadingButton>
          </Container>
        </Paper>
      </FormControl>
    </Box>
  );
};

export default connector(UserSearch);
