// external
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Relationships: React.FunctionComponent = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Outlet />
    </Box>
  );
};

export default Relationships;
