import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProgram {
  _id: string;
  id: { _type: 'slug'; current: string };
  logo: {
    _type: 'image';
    asset: {
      _ref: string;
      _type: 'reference';
    };
  };
  program_name: string;
  program_description: string;
  type: {
    program_type: string;
  };
  company?: {
    name: string;
  };
  profile_fields?: IProfileField[];
  specialization_options?: { label: string; value: string }[];
}

export interface IProfileField {
  name: string;
  label: string;
  type: string;
  active: boolean;
  hidden: { coach: boolean; trainee: boolean };
  validate: {
    alpha?: boolean;
    required?: boolean;
    regex?: string;
    max?: number;
    url?: boolean;
    email?: boolean;
  };
  options?: { label: string; value: string }[];
}

export interface IProgramUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  registeredOn: number;
  lastAccessed: number;
  activeRelationshipsCount: string;
  role: string;
}

export interface IProgramUsers {
  users: IProgramUser[];
  usersCount: number;
}

export interface IPrograms {
  list: IProgram[];
  users: IProgramUser[];
  usersCount: number;
}

const initialState: IPrograms = {
  list: [],
  users: [],
  usersCount: 0,
};

export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setPrograms: (state, action: PayloadAction<IProgram[]>) => {
      const programs = action.payload;

      state.list =
        programs &&
        programs.filter((program) => program.id && !!program.id.current);
    },
    setProgramUsers: (state, action: PayloadAction<IProgramUsers>) => {
      const { users, usersCount } = action.payload;

      state.users = users;
      state.usersCount = usersCount;
    },
    resetProgramUsers: (state) => {
      state.users = [];
      state.usersCount = 0;
    },
  },
});

export const { setPrograms, setProgramUsers, resetProgramUsers } =
  programSlice.actions;

export default programSlice.reducer;
