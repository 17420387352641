// external
import React from 'react';
import { Box, CardMedia, SxProps, Fade } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { keyframes } from '@emotion/react';

// assets
import guiderAdminLogo from '../../assets/images/logo-guider-admin.svg';

// store
import { RootState } from 'store';

const mapState = (state: RootState) => {
  return {
    appLoading: state.app.loading,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Loading: React.FunctionComponent<PropsFromRedux> = ({ appLoading }) => {
  let additionalStyles: SxProps = {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    bgcolor: '#fafafa',
  };

  if (appLoading.size === 'small') {
    additionalStyles = {
      width: '100%',
      height: '100%',
      position: 'absolute',
    };
  }

  const pulse = keyframes`
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.2);
  }
  `;

  return (
    <Fade appear={false} in={appLoading.show} timeout={1000}>
      <Box
        sx={{
          zIndex: 10000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          left: 0,
          ...additionalStyles,
        }}
      >
        <CardMedia
          component="img"
          image={guiderAdminLogo}
          alt="Loading"
          sx={{
            width: '400px',
            animation: `${pulse} 2s ease-in-out infinite;`,
          }}
        />
      </Box>
    </Fade>
  );
};

export default connector(Loading);
