// external
import {
  ImageFormat,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';

// internal
import { imageBuilder } from 'sanity';

export const buildSanityImageUrl = ({
  source,
  width,
  height,
  format = 'webp',
}: {
  source: SanityImageSource;
  width?: number;
  height?: number;
  format?: ImageFormat;
}): string => {
  let image = imageBuilder.image(source);

  if (width) {
    image = image.width(width);
  }

  if (height) {
    image = image.height(height);
  }

  return image.format(format).url();
};
