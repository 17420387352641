import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

//slices
import sidebarSlice from './slices/sidebarSlice';
import userSlice from './slices/userSlice';
import appSlice from './slices/appSlice';
import guiderUserSlice from './slices/guiderUserSlice';
import programSlice from './slices/programSlice';
import relationshipsSlice from './slices/relationshipsSlice';

export const mainReducer = {
  app: appSlice,
  user: userSlice,
  guiderUser: guiderUserSlice,
  sidebar: sidebarSlice,
  program: programSlice,
  relationships: relationshipsSlice,
};

export const store = configureStore({
  reducer: mainReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
