// external
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface IDeleteDialogBoxProps {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  handleDelete?: () => void;
  title?: string;
  description?: string;
  deleteString?: string;
}

const DeleteDialogBox: React.FunctionComponent<IDeleteDialogBoxProps> = ({
  open,
  loading,
  handleClose,
  handleDelete,
  description,
  title,
  deleteString,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleClose} disabled={loading}>
          Cancel
        </LoadingButton>
        <LoadingButton color="error" onClick={handleDelete} loading={loading}>
          {deleteString}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialogBox;
