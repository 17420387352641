// external
import { InsertDriveFile } from '@mui/icons-material';
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Link,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { format } from 'date-fns';

// internal
import { buildSanityImageUrl } from 'sanity';
import history from 'utils/history';
import { useFunctions } from 'hooks/useFunctions';

// components
import SkeletonTable from 'components/Skeleton/SkeletonTable';

// store
import { RootState } from 'store';
import { resetProgramUsers, setProgramUsers } from 'store/slices/programSlice';

const mapState = (state: RootState) => {
  return {
    programs: state.program.list,
    users: state.program.users,
    usersCount: state.program.usersCount,
  };
};

const mapDispatch = {
  setProgramUsers,
  resetProgramUsers,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ProgramDetails: React.FunctionComponent<PropsFromRedux> = ({
  programs,
  users,
  usersCount,
  setProgramUsers,
  resetProgramUsers,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { programId } = useParams();
  const { getProgramUsers } = useFunctions();

  const currentProgram = programs.filter(
    (program) => program.id.current === programId
  )[0];

  if (!currentProgram) {
    history.push('/programs');
  }

  const { logo, program_name, program_description, company, type, id } =
    currentProgram;

  const currentFrom = page * rowsPerPage;
  const currentTo = currentFrom + rowsPerPage;

  const indexFrom = currentFrom;
  const indexTo = currentTo - 1;

  useEffect(() => {
    setIsLoading(true);

    const _getProgramUsers = async () => {
      return getProgramUsers(currentProgram.id.current, indexFrom, indexTo);
    };

    _getProgramUsers()
      .then((response) => {
        setProgramUsers(response);
        setIsLoading(false);
      })
      .catch((e) => {
        console.warn('could not get program users: ', e);
        setIsLoading(false);
      });

    return function cleanup() {
      resetProgramUsers();
    };
  }, [currentProgram, page, rowsPerPage]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderUsers = users.map((user) => {
    const {
      id,
      firstName,
      lastName,
      email,
      registeredOn,
      lastAccessed,
      activeRelationshipsCount,
      role,
    } = user;
    return (
      <TableRow
        key={`program-${id}`}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {firstName}
        </TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{format(registeredOn, 'dd/MM/yyyy')}</TableCell>
        <TableCell>
          {lastAccessed ? format(lastAccessed, 'dd/MM/yyyy') : 'N/A'}
        </TableCell>
        <TableCell>{activeRelationshipsCount}</TableCell>
        <TableCell>{role}</TableCell>
        <TableCell>
          <Link
            component={RouterLink}
            to={`/users/${id}`}
            sx={{ color: theme.palette.info.dark }}
          >
            View profile
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const renderTable =
    isLoading || Object.keys(users).length === 0 ? (
      <SkeletonTable columns={8} rows={rowsPerPage} />
    ) : (
      renderUsers
    );

  return (
    <>
      <Paper
        sx={{ p: 2, my: 3, display: 'flex', alignItems: 'flex-start' }}
        variant="outlined"
      >
        <CardMedia
          sx={{
            width: '93px',
            height: '93px',
            mr: 2,
            objectFit: 'contain',
          }}
          component="img"
          image={`${buildSanityImageUrl({ source: logo })}`}
        />
        <Box>
          <Typography variant="caption">{company?.name}</Typography>
          <Typography variant="h6" component="h2" sx={{ fontWeight: '600' }}>
            {program_name}
          </Typography>
          <Chip label={type.program_type} color="info" size="small" />
          {program_description && (
            <Typography>{program_description}</Typography>
          )}
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Button
              variant="outlined"
              startIcon={<InsertDriveFile />}
              size="small"
              color="info"
              sx={{ mr: 1 }}
              href={`https://app.getguider.co/landing/${id.current}/programme`}
              target="_blank"
            >
              Landing Page
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ p: 2 }} variant="outlined">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h6" component="h2" sx={{ fontWeight: '600' }}>
            Users
          </Typography>
          {/* <Button
            variant="outlined"
            startIcon={<Email />}
            size="small"
            color="info"
          >
            Email users
          </Button> */}
        </Box>
        <TableContainer>
          <Table sx={{ width: '100%' }} aria-label="programs table">
            <TableHead>
              <TableRow>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email address</TableCell>
                <TableCell>Registered on</TableCell>
                <TableCell>Last accessed</TableCell>
                <TableCell>
                  Active
                  <br />
                  relationships
                </TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTable}</TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={usersCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default connector(ProgramDetails);
