// external
import { Skeleton, TableCell, TableRow } from '@mui/material';

interface ISkeletonTableProps {
  rows?: number;
  columns?: number;
}

const SkeletonTable: React.FunctionComponent<ISkeletonTableProps> = ({
  rows = 10,
  columns = 5,
}) => {
  const renderTable = () =>
    Array.from({ length: rows }, (_row, rowIndex) => {
      return (
        <TableRow key={`skeleton-row-${rowIndex}`}>
          {Array.from({ length: columns }, (_column, columnIndex) => (
            <TableCell key={`skeleton-column-${columnIndex}`}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      );
    });

  return <>{renderTable()}</>;
};

export default SkeletonTable;
