// @typescript-eslint/explicit-module-boundary-types

// external
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';

// internal
import { getFirebaseErrorMessage, IFirebaseError } from 'firebase/utils';
import { functions } from 'firebase';

// store
import { IUser } from 'store/slices/userSlice';
import { IGuiderUser } from 'store/slices/guiderUserSlice';
import { IProgram, IProgramUsers } from 'store/slices/programSlice';
import {
  IGuiderUserRelationships,
  IRelationshipDetails,
} from 'store/slices/relationshipsSlice';

interface IUseFunctions {
  (): {
    deleteGuiderUser: (guiderUserId: string) => Promise<HttpsCallableResult>;
    deleteGuiderRelationship: (
      relationshipId: string
    ) => Promise<HttpsCallableResult>;
    deleteGuiderCalendarIntegration: (
      guiderUserId: string
    ) => Promise<HttpsCallableResult>;
    deleteGuiderAvailability: (
      id: string,
      availabilityId: string
    ) => Promise<HttpsCallableResult>;
    getUser: () => Promise<IUser>;
    getProgramUsers: (
      programId: string,
      indexFrom?: number,
      indexTo?: number,
      orderBy?: string,
      order?: string
    ) => Promise<IProgramUsers>;
    getGuiderUser: (guiderUserIdOrEmail: string) => Promise<IGuiderUser>;
    getGuiderUserByEmail: (email: string) => Promise<IGuiderUser>;
    getGuiderUserById: (guiderUserId: string) => Promise<IGuiderUser>;
    updateGuiderUserById: (
      guiderUserId: string,
      updates: Partial<IGuiderUser>
    ) => Promise<HttpsCallableResult>;
    getPrograms: () => Promise<IProgram[]>;
    getProgramById: (programId: string) => Promise<IProgram>;
    getRelationshipsByUser: (
      guiderUserIdorEmail: string
    ) => Promise<IGuiderUserRelationships>;
    getRelationshipDetails: (
      relationshipId: string
    ) => Promise<IRelationshipDetails>;
    createRelationship: (
      guideEmail: string,
      traineeEmail: string,
      programId: string
    ) => Promise<string>;
    concludeRelationship: (relationshipId: string) => Promise<string>;
    getHolisticsToken: () => Promise<string>;
  };
}

export const useFunctions: IUseFunctions = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, logout } =
    useAuth0();

  const opts = {
    scope: 'openid',
  };

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(opts);
    } catch (error) {
      const errorJson: OAuthError = JSON.parse(JSON.stringify(error));
      const errorId = errorJson.error;

      if (errorId === 'consent_required') {
        return getAccessTokenWithPopup(opts);
      } else {
        console.error(error);
        logout();
        throw new Error('Unauthenticated');
      }
    }
  };

  const firebaseFunction = async (
    functionName: string,
    data?: { [key: string]: unknown }
  ) => {
    const accessToken = await getAccessToken();
    const functionCallable = httpsCallable(functions, functionName);

    return functionCallable({ accessToken, ...data });
  };

  const deleteGuiderUser = async (
    guiderUserId: string
  ): Promise<HttpsCallableResult> => {
    try {
      return await firebaseFunction('auth-deleteGuiderUser', {
        guiderUserId,
      });
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const deleteGuiderRelationship = async (
    relationshipId: string
  ): Promise<HttpsCallableResult> => {
    try {
      return await firebaseFunction('firestore-deleteGuiderRelationship', {
        relationshipId,
      });
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const deleteGuiderCalendarIntegration = async (
    guiderUserId: string
  ): Promise<HttpsCallableResult> => {
    try {
      return await firebaseFunction(
        'firestore-deleteGuiderCalendarIntegration',
        {
          guiderUserId,
        }
      );
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const deleteGuiderAvailability = async (
    id: string,
    availabilityId: string
  ): Promise<HttpsCallableResult> => {
    try {
      return await firebaseFunction('firestore-deleteGuiderAvailability', {
        id,
        availabilityId,
      });
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getUser = async (): Promise<IUser> => {
    try {
      const res = await firebaseFunction('auth-getUser');

      return res.data as IUser;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getProgramUsers = async (
    programId: string,
    indexFrom = 0,
    indexTo = 9,
    orderBy = 'registeredOn',
    order = 'desc'
  ): Promise<IProgramUsers> => {
    try {
      const res = await firebaseFunction('firestore-getProgramUsers', {
        programId,
        indexFrom,
        indexTo,
        orderBy,
        order,
      });

      return res.data as IProgramUsers;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getGuiderUser = async (
    guiderUserIdOrEmail: string
  ): Promise<IGuiderUser> => {
    try {
      const res = await firebaseFunction('firestore-getGuiderUser', {
        guiderUserIdOrEmail,
      });

      return res.data as IGuiderUser;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getGuiderUserByEmail = async (email: string): Promise<IGuiderUser> => {
    try {
      const res = await firebaseFunction('firestore-getGuiderUserByEmail', {
        email,
      });

      return res.data as IGuiderUser;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getGuiderUserById = async (
    guiderUserId: string
  ): Promise<IGuiderUser> => {
    try {
      const res = await firebaseFunction('firestore-getGuiderUserById', {
        guiderUserId,
      });

      return res.data as IGuiderUser;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const updateGuiderUserById = async (
    guiderUserId: string,
    updates: Partial<IGuiderUser>
  ): Promise<HttpsCallableResult> => {
    try {
      return await firebaseFunction('firestore-updateGuiderUserById', {
        guiderUserId,
        updates,
      });
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getPrograms = async (): Promise<IProgram[]> => {
    try {
      const res = await firebaseFunction('sanity-getPrograms');

      return res.data as IProgram[];
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getProgramById = async (programId: string): Promise<IProgram> => {
    try {
      const res = await firebaseFunction('sanity-getProgramById', {
        programId,
      });

      return res.data as IProgram;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getRelationshipsByUser = async (
    guiderUserIdOrEmail: string
  ): Promise<IGuiderUserRelationships> => {
    try {
      const res = await firebaseFunction('firestore-getRelationshipsByUser', {
        guiderUserIdOrEmail,
      });

      return res.data as IGuiderUserRelationships;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getRelationshipDetails = async (
    relationshipId: string
  ): Promise<IRelationshipDetails> => {
    try {
      const res = await firebaseFunction('firestore-getRelationshipDetails', {
        relationshipId,
      });

      return res.data as IRelationshipDetails;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const createRelationship = async (
    guideEmail: string,
    traineeEmail: string,
    programId: string
  ): Promise<string> => {
    try {
      const res = await firebaseFunction('firestore-createRelationship', {
        guideEmail,
        traineeEmail,
        programId,
      });

      return res.data as string;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const concludeRelationship = async (
    relationshipId: string
  ): Promise<string> => {
    try {
      const res = await firebaseFunction('firestore-concludeRelationship', {
        relationshipId,
      });
      return res.data as string;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  const getHolisticsToken = async (): Promise<string> => {
    try {
      const res = await firebaseFunction('metrics-getHolisticsToken');
      return res.data as string;
    } catch (error: unknown) {
      throw new Error(getFirebaseErrorMessage(error as IFirebaseError));
    }
  };

  return {
    deleteGuiderUser,
    deleteGuiderRelationship,
    deleteGuiderCalendarIntegration,
    deleteGuiderAvailability,
    getUser,
    getProgramUsers,
    getGuiderUser,
    getGuiderUserByEmail,
    getGuiderUserById,
    updateGuiderUserById,
    getPrograms,
    getProgramById,
    getRelationshipsByUser,
    getRelationshipDetails,
    createRelationship,
    concludeRelationship,
    getHolisticsToken,
  };
};
