// external
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Outlet, useParams, Link as RouterLink } from 'react-router-dom';

const Program: React.FunctionComponent = () => {
  const { programId } = useParams();
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Typography
        color="inherit"
        variant="h4"
        component="h1"
        sx={{ fontWeight: 'bold', mb: 1 }}
      >
        Programs
      </Typography>
      <Typography>
        <Link
          component={RouterLink}
          to={'/programs'}
          sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}
        >
          All programs
        </Link>
        {programId && ` / ${programId}`}
      </Typography>
      <Outlet />
    </Box>
  );
};

export default Program;
