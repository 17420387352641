import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface IAppLoading {
  show: boolean;
  size?: 'large' | 'small';
}

export interface IAppError {
  show: boolean;
  message: string;
}

export interface IAppSuccess {
  show: boolean;
  message: string;
}

export interface IApp {
  loading: IAppLoading;
  error: IAppError;
  success: IAppSuccess;
}

const initialState: IApp = {
  loading: {
    show: true,
    size: 'large',
  },
  error: {
    show: false,
    message: '',
  },
  success: {
    show: false,
    message: '',
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<IAppLoading>) => {
      state.loading = action.payload;
    },
    setAppError: (state, action: PayloadAction<string>) => {
      if (action.payload === 'INTERNAL') {
        state.error.message =
          'Something went wrong, please try again later. If this issue persists please contact support';
      } else {
        state.error.message = action.payload;
      }
      state.error.show = true;
    },
    hideAppError: (state) => {
      state.error.show = false;
    },
    setAppSuccess: (state, action: PayloadAction<string>) => {
      state.success.message = action.payload;
      state.success.show = true;
    },
    hideAppSuccess: (state) => {
      state.success.show = false;
    },
  },
});

export const {
  setAppLoading,
  setAppError,
  hideAppError,
  setAppSuccess,
  hideAppSuccess,
} = appSlice.actions;

export const selectAppLoading = (state: RootState): IAppLoading =>
  state.app.loading;

export default appSlice.reducer;
