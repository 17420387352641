import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface ISidebar {
  show: boolean;
}

const initialState: ISidebar = {
  show: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.show = !state.show;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export const selectShowSidebar = (state: RootState): boolean =>
  state.sidebar.show;

export default sidebarSlice.reducer;
