// external
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Chip,
  Typography,
  Breadcrumbs,
  Button,
} from '@mui/material';

// internal
import { useFunctions } from 'hooks/useFunctions';
import history from 'utils/history';

// components
import SkeletonTable from 'components/Skeleton/SkeletonTable';
import RelationshipDialogBox from 'components/DialogBox/RelationshipDialogBox';

// store

import { RootState } from 'store';
import {
  setRelationships,
  setRelationshipDetails,
  resetRelationships,
} from 'store/slices/relationshipsSlice';
import { setAppLoading, setAppError } from 'store/slices/appSlice';
import firstLetterUppercase from 'utils/firstLetterUppercase';

const mapState = (state: RootState) => {
  return {
    relationships: state.relationships,
  };
};

const mapDispatch = {
  setRelationships,
  setRelationshipDetails,
  resetRelationships,
  setAppLoading,
  setAppError,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const RelationshipsList: React.FunctionComponent<PropsFromRedux> = ({
  relationships,
  setRelationships,
  // setRelationshipDetails,
  resetRelationships,
  setAppLoading,
  setAppError,
}) => {
  const [loading, setLoading] = useState(false);
  const [relationshipDialogOpen, setRelationshipDialogOpen] = useState(false);

  const { guiderUserId } = useParams();

  const { getRelationshipsByUser } = useFunctions();

  useEffect(() => {
    if (
      guiderUserId &&
      Object.keys(relationships.list).length === 0 &&
      guiderUserId !== relationships.guiderUserInfo.id
    ) {
      const findRelationships = async () => {
        setAppLoading({ show: true, size: 'small' });
        setLoading(true);
        return getRelationshipsByUser(guiderUserId);
      };

      findRelationships()
        .then((guiderUserRelationships) => {
          setRelationships(guiderUserRelationships);
          setAppLoading({ show: false, size: 'small' });
          setLoading(false);

          if (guiderUserRelationships.list.length === 0) {
            setAppError(
              `The email ${guiderUserRelationships.guiderUserInfo.email} does not have any relationships`
            );
          }
        })
        .catch((error) => {
          setAppError(error.message);
          setAppLoading({ show: false, size: 'small' });
        });
    }

    return function cleanup() {
      resetRelationships();
    };
  }, []);

  const goToRelationships = () => {
    history.push('/relationships');
  };

  const handleGoToProfile = (userId: string) => {
    history.push(`/users/${userId}`);
  };

  const handleRelationshipDialogClose = () => {
    setRelationshipDialogOpen(false);
  };
  const handleLoadingDialog = (boolean: boolean) => {
    setLoading(boolean);
  };

  const renderRelationshipsRows = relationships.list.map((relationship) => {
    const name = `${relationship.otherParty.firstName} ${relationship.otherParty.lastName}`;
    const role = firstLetterUppercase(relationship.otherParty.role);
    const programName = firstLetterUppercase(relationship.programName);
    return (
      <TableRow key={`relationship-${relationship.id}`}>
        <TableCell>
          {name}
          <Chip label={role} size="small" sx={{ ml: 1 }} />
        </TableCell>
        <TableCell>
          <Link
            component={RouterLink}
            to={`/users/${relationship.otherParty.id}`}
            sx={{ color: 'info.dark' }}
          >
            {relationship.otherParty.email}
          </Link>
        </TableCell>
        <TableCell>{programName}</TableCell>
        <TableCell>{relationship.requestedOn}</TableCell>
        <TableCell>{relationship.status}</TableCell>
        <TableCell>
          <Link
            component={RouterLink}
            to={`/relationships/${relationship.id}`}
            sx={{ color: 'info.dark' }}
            onClick={() => {
              setAppLoading({ show: true, size: 'small' });
            }}
          >
            View details
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const renderTable = loading ? (
    <SkeletonTable rows={3} columns={6} />
  ) : (
    renderRelationshipsRows
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        color="inherit"
        variant="h4"
        component="h2"
        sx={{ fontWeight: 'bold', mb: 1 }}
      >
        {relationships.guiderUserInfo.firstName
          ? `${relationships.guiderUserInfo.firstName}'s Relationships`
          : `User's Relationships`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={goToRelationships}
            sx={{ cursor: 'pointer' }}
            aria-label="users"
          >
            Relationships
          </Link>
          <Typography color="text.secondary" sx={{ opacity: 0.6 }}>
            {relationships.guiderUserInfo.email || '...'}
          </Typography>
        </Breadcrumbs>
        <Box>
          <Button
            variant="outlined"
            color="info"
            onClick={() => handleGoToProfile(relationships.guiderUserInfo.id)}
            sx={{ mr: 2 }}
          >
            {relationships.guiderUserInfo.firstName
              ? `View ${relationships.guiderUserInfo.firstName}'s Profile`
              : `View User's Profile`}
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={() => setRelationshipDialogOpen(true)}
          >
            Create Relationship
          </Button>
        </Box>
      </Box>
      <Paper sx={{ p: 2, mt: 3, pb: 8 }} variant="outlined">
        {relationships.list && (
          <TableContainer>
            <Table sx={{ width: '100%' }} aria-label="user relationships table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Program name</TableCell>
                  <TableCell>Requested on</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTable}</TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <RelationshipDialogBox
        userName={`${relationships.guiderUserInfo.firstName} ${relationships.guiderUserInfo.lastName}`}
        userEmail={relationships.guiderUserInfo.email}
        userPrograms={relationships.guiderUserInfo.programs}
        open={relationshipDialogOpen}
        handleClose={handleRelationshipDialogClose}
        setLoading={handleLoadingDialog}
      />
    </Box>
  );
};

export default connector(RelationshipsList);
