//external
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';

//components
import GenericError from 'components/GenericError/GenericError';
import AppContainer from 'components/AppContainer/AppContainer';

//store
import { RootState } from 'store';

const mapState = (state: RootState) => {
  return {
    appLoading: state.app.loading,
    appUser: state.user,
    appUserLoaded: state.user.loaded,
    userError: state.user.error,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const theme = createTheme({
  palette: {
    primary: {
      main: '#5C58F4',
    },
    secondary: {
      main: '#7B2EB2',
    },
    error: {
      main: '#EA0000',
    },
    info: {
      main: '#3771D0',
    },
    success: {
      main: '#188049',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

const App: React.FunctionComponent<PropsFromRedux> = () => {
  const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env;

  return (
    <ThemeProvider theme={theme}>
      {REACT_APP_AUTH0_CLIENT_ID && REACT_APP_AUTH0_DOMAIN ? (
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
        >
          <AppContainer />
        </Auth0Provider>
      ) : (
        <GenericError
          code="CONFIG-AUTH"
          message="Configuration data for the authentication solution is not available or invalid."
        />
      )}
    </ThemeProvider>
  );
};

export default connector(App);
