import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRelationship {
  otherParty: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
  programName: string;
  requestedOn: string;
  status: string;
  id: string;
}

interface IRelationshipParty {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  programs?: { trainee: string[]; coach: string[] };
}

interface IRelationshipGoal {
  category: string;
  title: string;
  timeline: number;
  description: string;
  status: string;
}

export interface IRelationshipDetails {
  guide: IRelationshipParty;
  trainee: IRelationshipParty;
  programName: string;
  type: string;
  requestedOn: string;
  duration: number;
  status: string;
  goals: IRelationshipGoal[];
  // sessions: IRelationshipSession[];
}

export interface IGuiderUserRelationships {
  list: IRelationship[];
  guiderUserInfo: IRelationshipParty;
}

export interface IRelationships {
  guiderUserInfo: IRelationshipParty;
  list: IRelationship[];
  relationshipDetails: IRelationshipDetails;
}

const initialState: IRelationships = {
  guiderUserInfo: {} as IRelationshipParty,
  list: [],
  relationshipDetails: {} as IRelationshipDetails,
};

export const relationshipsSlice = createSlice({
  name: 'relationships',
  initialState,
  reducers: {
    setRelationships: (
      state,
      action: PayloadAction<IGuiderUserRelationships>
    ) => {
      const relationships = action.payload.list;
      const guiderUserInfo = action.payload.guiderUserInfo;

      state.list = relationships;
      state.guiderUserInfo = guiderUserInfo;
    },
    setRelationshipDetails: (
      state,
      action: PayloadAction<IRelationshipDetails>
    ) => {
      const relationshipDetails = action.payload;
      state.relationshipDetails = relationshipDetails;
    },
    resetRelationshipDetails: (state) => {
      state.relationshipDetails = initialState.relationshipDetails;
    },
    resetRelationships: () => initialState,
  },
});

export const {
  setRelationships,
  setRelationshipDetails,
  resetRelationships,
  resetRelationshipDetails,
} = relationshipsSlice.actions;

export default relationshipsSlice.reducer;
