import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IProfileField } from './programSlice';

export interface IRelationship {
  otherParty: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    photo: string;
  };
  relationshipId: string;
  relationshipGroupId: string;
  role: string;
}

export interface IAvailabilities {
  availabilityId: string;
  startISO: string;
}

export interface IComm {
  [key: string]: {
    preferred: boolean;
    name: string;
    enabled: boolean;
    value?: string;
  };
}

export interface IGuiderUser {
  groupsListedIn: {
    [key: string]: {
      coach?: { listed: boolean };
      trainee?: { listed: boolean };
    };
  };
  updates: {
    [key: string]: {
      data?: { type?: string } | null;
      updated?: number;
    };
  };
  roles: { coach?: boolean; trainee?: boolean; stakeholder?: boolean };
  profiles: {
    group: string;
    coach?: {
      skills: {
        specialization: {
          [key: string]: boolean;
        };
      };
      bio: {
        description: string;
        offer: string;
        personal: string;
        professional: string;
      };
    };
    trainee?: {
      skills_sought: {
        specialization: {
          [key: string]: boolean;
        };
      };
      bio: string;
    };
  }[];
  auth: {
    office365: {
      params: {
        token_type: string;
        ms_account: string;
        expires_in: number;
        scope: string;
        access_token: string;
        ext_expires_in: number;
        refresh_token: string;
        id_token: string;
        expires_at: number;
      };
      oid: string;
    };
  };
  settings: {
    integrations: {
      gsuite: { permanentlyDismissed: boolean; dismissedAt: boolean };
      office365: { permanentlyDismissed: boolean; dismissedAt: boolean };
    };
  };
  locales: {
    group: string;
    programsGroupId: string;
    lang: string;
    internal: boolean;
    type: string;
    [key: string]: boolean | string;
  };
  groups: {
    [key: string]: {
      type: string;
      givenaccess: number;
    };
  };
  id: string;
  coach: {
    bio: {
      [key: string]: string;
    };
    skills: {
      specialization: {
        [key: string]: boolean;
      };
    };
  };
  trainee: {
    bio: string;
    skills_sought: {
      specialization: {
        [key: string]: boolean;
      };
    };
  };
  info: {
    jobRole: string;
    location: string;
    email: string;
    country: string;
    lastName: string;
    firstName: string;
    linkedinUrl: string;
    comms: IComm;
    personalTraits: string[];
    [key: string]: string | string[] | IComm;
  };
  metrics?: string[];
  photo?: string;
  relationships?: IRelationship[];
  availabilities?: IAvailabilities[];
  programsSkills?: {
    [key: string]: { label: string; value: string }[];
  };
  infoFields: IProfileField[];
}

const initialState: IGuiderUser = {} as unknown as IGuiderUser;

export const guiderUserSlice = createSlice({
  name: 'guiderUser',
  initialState,
  reducers: {
    setGuiderUser: (state, action: PayloadAction<IGuiderUser>) =>
      action.payload,
    resetGuiderUser: () => initialState,
    deleteRelationship: (state, action: PayloadAction<string>) => {
      state.relationships = state.relationships?.filter(
        (relationship) => relationship.relationshipId !== action.payload
      );
    },
  },
});

export const { setGuiderUser, resetGuiderUser, deleteRelationship } =
  guiderUserSlice.actions;

export const selectGuiderUser = (state: RootState): Partial<IGuiderUser> =>
  state.guiderUser;

export default guiderUserSlice.reducer;
