// external
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  AppBar,
  IconButton,
  Avatar,
  Grid,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon, AccountCircle, Logout } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

// assets
import colors from '../../styles/_colors.module.scss';

// store
import { RootState } from 'store';
import { resetUser } from 'store/slices/userSlice';

const mapState = (state: RootState) => {
  return {
    appUser: state.user,
  };
};

const mapDispatch = {
  resetUser,
};

interface HeaderProps extends PropsFromRedux {
  handleMobileDrawerToggle: () => void;
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Header: React.FunctionComponent<HeaderProps> = ({
  appUser,
  handleMobileDrawerToggle,
}) => {
  const { logout } = useAuth0();
  const { email, displayName, photoURL } = appUser?.info || {};

  const handleLogout = async () => {
    logout();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        color="inherit"
        position="sticky"
        elevation={0}
        sx={{
          py: 1,
          background:
            'radial-gradient(100% 381.69% at 100% 131.67%, #00E3FF 0%, #5C58F3 48.96%, #1E1B99 100%)',
        }}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item sx={{ display: { sm: 'none', xs: 'block' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMobileDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-label="open profile"
                  data-testid="profileIcon"
                >
                  <AccountCircle sx={{ color: `${colors.headerUser}` }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0.3,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem sx={{ pl: 1, pr: 3, py: 0 }}>
                  <IconButton onClick={handleClick} size="small" sx={{ mr: 1 }}>
                    <Avatar
                      src={photoURL}
                      sx={{ width: 50, height: 50 }}
                      alt={displayName}
                    />
                  </IconButton>
                  <Grid
                    container
                    sx={{
                      color: `${colors.brandGray}`,
                      textAlign: 'left',
                      flexDirection: 'column',
                    }}
                  >
                    <Grid item>
                      <Typography sx={{ fontSize: '14px' }}>
                        {displayName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ fontSize: '12px' }}>{email}</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={handleLogout}
                  sx={{ color: `${colors.headerMenuButtonRed}`, py: 0 }}
                >
                  <ListItemText>Sign out</ListItemText>
                  <ListItemIcon sx={{ justifyContent: 'right' }}>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default connector(Header);
