import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Menu,
  MenuItem,
  Divider,
  Paper,
  Grid,
} from '@mui/material';
import { ArrowDropDown, Close, Delete } from '@mui/icons-material';
import { useParams, Link as RouterLink } from 'react-router-dom';

// internal
import { useFunctions } from 'hooks/useFunctions';
import history from 'utils/history';
import firstLetterUppercase from 'utils/firstLetterUppercase';
import { getRoleName } from 'utils/getRoleName';

// components
import DeleteDialogBox from 'components/DialogBox/DeleteDialogBox';

// store
import { RootState } from 'store';
import {
  setRelationshipDetails,
  resetRelationshipDetails,
} from 'store/slices/relationshipsSlice';
import {
  setAppLoading,
  setAppError,
  setAppSuccess,
} from 'store/slices/appSlice';

const mapState = (state: RootState) => {
  return {
    relationships: state.relationships,
  };
};

const mapDispatch = {
  setRelationshipDetails,
  resetRelationshipDetails,
  setAppLoading,
  setAppError,
  setAppSuccess,
};

export interface IDeleteDialogProps {
  title?: string;
  description?: string;
  deleteString?: string;
  handleDelete?: () => void;
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const RelationshipDetails: React.FunctionComponent<PropsFromRedux> = ({
  relationships,
  setRelationshipDetails,
  resetRelationshipDetails,
  setAppLoading,
  setAppError,
  setAppSuccess,
}) => {
  const [actionsAnchor, setActionsAnchor] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] =
    useState<IDeleteDialogProps>({});

  const {
    getRelationshipDetails,
    deleteGuiderRelationship,
    concludeRelationship,
  } = useFunctions();

  const { relationshipId } = useParams();

  useEffect(() => {
    if (relationshipId) {
      setAppLoading({ show: true, size: 'small' });
      setLoading(true);

      const findRelationshipDetails = async () => {
        return getRelationshipDetails(relationshipId);
      };

      findRelationshipDetails()
        .then((relationshipDetails) => {
          setRelationshipDetails(relationshipDetails);
          setAppLoading({ show: false, size: 'small' });
          setLoading(false);
        })
        .catch((error) => {
          setAppError(error.message);
          setAppLoading({ show: false, size: 'small' });
        });
    }

    return function cleaunp() {
      resetRelationshipDetails();
    };
  }, []);

  if (Object.keys(relationships.relationshipDetails).length === 0) return <></>;

  const guide = relationships.relationshipDetails.guide;
  const trainee = relationships.relationshipDetails.trainee;
  const programName = relationships.relationshipDetails.programName;

  const goToRelationships = () => {
    history.push('/relationships');
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchor(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsAnchor(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTimeout(() => {
      setDeleteDialogProps({});
    }, 1000);
  };

  const handleRelationshipDelete = async () => {
    setLoading(true);
    try {
      relationshipId && (await deleteGuiderRelationship(relationshipId));
      setAppSuccess(
        `The relationship in the program ${programName} between ${guide.firstName} ${guide.lastName} and ${trainee.firstName} ${trainee.lastName} has been successfully deleted.`
      );
      resetRelationshipDetails();
    } catch (error) {
      let errorMessage =
        'Something went wrong, please try again later. If this issue persists please contact support';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setAppError(errorMessage);
    }
    setLoading(false);
    goToRelationships();
  };

  const handleRelationshipConclusion = async () => {
    setLoading(true);
    try {
      relationshipId && (await concludeRelationship(relationshipId));
      setAppSuccess(
        `The relationship in the program ${firstLetterUppercase(
          programName
        )} between ${guide.firstName} ${guide.lastName} and ${
          trainee.firstName
        } ${trainee.lastName} has been ended.`
      );
    } catch (error) {
      let errorMessage =
        'Something went wrong, please try again later. If this issue persists please contact support';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setAppError(errorMessage);
    }
    setLoading(false);
    handleDeleteDialogClose();
  };

  const deleteRelationshipProps = {
    handleDelete: handleRelationshipDelete,
    title: 'Delete relationship',
    description:
      'Are you sure you want to delete this relationship? This will delete all data associated with it.',
    deleteString: 'Delete relationship',
  };

  const concludeRelationshipProps = {
    handleDelete: handleRelationshipConclusion,
    title: 'End relationship',
    description: `Are you sure you want to end this relationship? This will preserve the relationship data. Users can still view their ended relationships.`,
    deleteString: 'End relationship',
  };

  const handleDeleteRelationshipClick = () => {
    setDeleteDialogProps(deleteRelationshipProps);
    setDeleteDialogOpen(true);
  };

  const handleConcludeRelationshipClick = () => {
    setDeleteDialogProps(concludeRelationshipProps);
    setDeleteDialogOpen(true);
  };

  const relationshipMetadata = [
    {
      label: firstLetterUppercase(
        getRoleName(relationships.relationshipDetails.type, 'guide')
      ),
      name: 'guide',
      value: `${guide.firstName} ${guide.lastName}`,
      link: `/users/${guide.id}`,
    },
    {
      label: firstLetterUppercase(
        getRoleName(relationships.relationshipDetails.type, 'trainee')
      ),
      name: 'trainee',
      value: `${trainee.firstName} ${trainee.lastName}`,
      link: `/users/${trainee.id}`,
    },
    {
      label: 'Requested on',
      name: 'requested',
      value: `${relationships.relationshipDetails.requestedOn}`,
      link: '',
    },
    {
      label: 'Relationship duration',
      name: 'duration',
      value:
        relationships.relationshipDetails.duration !== 0
          ? `${relationships.relationshipDetails.duration} days`
          : 'Requested today',
      link: '',
    },
    {
      label: 'Program',
      name: 'program',
      value: firstLetterUppercase(`${programName}`),
      link: `/programs/${programName}`,
    },
    {
      label: 'Relationship status',
      name: 'status',
      value: `${relationships.relationshipDetails.status}`,
      link: '',
    },
    {
      label: 'Program type',
      name: 'type',
      value: firstLetterUppercase(`${relationships.relationshipDetails.type}`),
      link: '',
    },
  ];

  const renderMetadata = () => {
    return (
      <Paper variant="outlined" sx={{ pb: 2, pl: 2, mt: 2 }}>
        <Typography
          color="inherit"
          variant="h5"
          component="h3"
          sx={{ fontWeight: 'bold', mb: 3, mt: 2 }}
        >
          Relationship metadata
        </Typography>
        <Grid container spacing={2} sx={{ pr: 2 }}>
          {relationshipMetadata.map((field) => {
            return (
              <Grid key={`${field.name}-field`} item xs={6}>
                <Box sx={{ ml: 2, mb: 1.5 }}>
                  <Typography variant="body2">{field.label}</Typography>
                  {!field.link && (
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {field.value}
                    </Typography>
                  )}
                  {field.link && (
                    <Link
                      component={RouterLink}
                      to={field.link}
                      sx={{ color: 'info.dark' }}
                    >
                      {field.value}
                    </Link>
                  )}
                </Box>
                <Divider />
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    );
  };

  const renderGoals = () => {
    return (
      <Paper variant="outlined" sx={{ pb: 2, pl: 2, my: 2 }}>
        <Typography
          color="inherit"
          variant="h5"
          component="h3"
          sx={{ fontWeight: 'bold', mb: 3, mt: 2 }}
        >
          Goals
        </Typography>
        {relationships.relationshipDetails.goals.map((goal, index) => {
          return (
            <Box key={`goal-${index}`}>
              <Typography
                color="inherit"
                variant="h6"
                component="h4"
                sx={{ fontWeight: 'bold', mb: 3, mt: 2 }}
              >
                {`Goal #${index + 1}`}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ ml: 2, mb: 1.5, mt: 1 }}>
                    <Typography variant="body2">Goal category</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {goal.category || 'N/A'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ ml: 2, mb: 1.5, mt: 1 }}>
                    <Typography variant="body2">Goal title</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {goal.title || 'N/A'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ ml: 2, mb: 1.5, mt: 1 }}>
                    <Typography variant="body2">Timeline</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {`${goal.timeline} months`}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ ml: 2, mb: 1.5, mt: 1 }}>
                    <Typography variant="body2">Status</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {goal.status}
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ ml: 2, mb: 1.5, mt: 1 }}>
                    <Typography variant="body2">Description</Typography>
                    <Typography variant="body1" sx={{ opacity: 0.54 }}>
                      {goal.description}
                    </Typography>
                  </Box>
                  <Divider />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Paper>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typography
          color="inherit"
          variant="h4"
          component="h2"
          sx={{ fontWeight: 'bold', mb: 1 }}
        >
          Relationship
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              onClick={goToRelationships}
              sx={{ cursor: 'pointer' }}
              aria-label="users"
            >
              Relationships
            </Link>
            <Typography color="text.secondary" sx={{ opacity: 0.6 }}>
              {relationshipId}
            </Typography>
          </Breadcrumbs>
          <Box>
            <Button
              variant="contained"
              color="info"
              sx={{ mx: 1 }}
              onClick={handleActionsClick}
            >
              Actions
              <ArrowDropDown sx={{ mr: -1 }} />
            </Button>

            <Menu
              open={Boolean(actionsAnchor)}
              anchorEl={actionsAnchor}
              onClose={handleActionsClose}
            >
              {relationships.relationshipDetails.status !== 'Concluded' && (
                <MenuItem
                  sx={{ pt: 0, pb: 0 }}
                  onClick={handleConcludeRelationshipClick}
                >
                  <Close sx={{ mr: 2 }} />
                  <Box sx={{ maxWidth: 1500 }}>
                    <Typography variant="body2" sx={{}}>
                      Conclude relationship
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.54 }}>
                      This will preserve the relationship <br /> data. Users can
                      still view their <br /> ended relationships.
                    </Typography>
                  </Box>
                </MenuItem>
              )}
              {relationships.relationshipDetails.status !== 'Concluded' && (
                <Divider />
              )}
              <MenuItem
                sx={{ pt: 0, pb: 0 }}
                onClick={handleDeleteRelationshipClick}
              >
                <Delete sx={{ mr: 2 }} />
                <Box sx={{ maxWidth: 1500 }}>
                  <Typography variant="body2" sx={{}}>
                    Delete relationship
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.54 }}>
                    This will delete all data associated <br /> with this
                    relationship. Users can no <br /> longer view this
                    relationship.
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box>
        {renderMetadata()}
        {relationships.relationshipDetails.goals &&
          relationships.relationshipDetails.goals.length > 0 &&
          renderGoals()}
      </Box>
      <DeleteDialogBox
        open={deleteDialogOpen}
        loading={loading}
        handleClose={handleDeleteDialogClose}
        handleDelete={deleteDialogProps.handleDelete}
        title={deleteDialogProps.title}
        description={deleteDialogProps.description}
        deleteString={deleteDialogProps.deleteString}
      />
    </Box>
  );
};

export default connector(RelationshipDetails);
