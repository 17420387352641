// external
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  TablePagination,
  Skeleton,
  useTheme,
  Paper,
} from '@mui/material';
import SkeletonTable from 'components/Skeleton/SkeletonTable';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// internal
import { useFunctions } from 'hooks/useFunctions';

// store
import { RootState } from 'store';
import { setPrograms } from 'store/slices/programSlice';

const mapState = (state: RootState) => {
  return {
    programs: state.program.list,
  };
};

const mapDispatch = {
  setPrograms,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ProgramList: React.FunctionComponent<PropsFromRedux> = ({
  programs,
  setPrograms,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { getPrograms } = useFunctions();

  useEffect(() => {
    if (programs.length === 0) {
      setIsLoading(true);

      const getProgramsFromSanity = async () => {
        return getPrograms();
      };

      getProgramsFromSanity()
        .then((response) => {
          setPrograms(response);
          setIsLoading(false);
        })
        .catch((e) => {
          console.warn('could not get programs from sanity: ', e);
        });
    }
  }, []);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPrograms =
    programs &&
    programs
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((program) => (
        <TableRow
          key={`program-${program.id.current}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {program.program_name}
          </TableCell>
          <TableCell>{program.id.current}</TableCell>
          <TableCell>
            <Link
              component={RouterLink}
              to={`/programs/${program.id.current}`}
              sx={{ color: theme.palette.info.dark }}
            >
              View details
            </Link>
          </TableCell>
        </TableRow>
      ));

  const renderTable = isLoading ? (
    <SkeletonTable columns={3} />
  ) : (
    renderPrograms
  );

  return (
    <Paper sx={{ p: 2, mt: 3 }} variant="outlined">
      {programs ? (
        <>
          <TableContainer>
            <Table sx={{ width: '100%' }} aria-label="programs table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '45%' }}>Program name</TableCell>
                  <TableCell sx={{ width: '40%' }}>Program ID</TableCell>
                  <TableCell sx={{ width: '15%' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTable}</TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={programs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Skeleton />
      )}
    </Paper>
  );
};

export default connector(ProgramList);
