import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

type userError = string | null | undefined;

export interface IUser {
  id: string;
  info: {
    displayName: string;
    email: string;
    photoURL: string;
  };
  loaded?: boolean;
  error?: userError;
}

const initialState: IUser = {} as IUser;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_state, action: PayloadAction<IUser>) => {
      const { id, info } = action.payload;

      return { id, info, loaded: true };
    },
    resetUser: () => {
      return {} as IUser;
    },
    setUserError: (state, action: PayloadAction<unknown>) => {
      let errorMessage = 'Uknown error, please contact support.';

      if (action.payload instanceof Error) {
        errorMessage = action.payload.message;
      }

      state.error = errorMessage;
    },
  },
});

export const { setUser, resetUser, setUserError } = userSlice.actions;

export const selectUser = (state: RootState): IUser => state.user;
export const selectUserError = (state: RootState): userError =>
  state.user.error;
export const selectUserLoaded = (state: RootState): boolean =>
  state.user.loaded || false;

export default userSlice.reducer;
