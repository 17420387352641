export const getRoleName = (programType: string, role: string): string => {
  let roleName = '';
  switch (role) {
    case 'trainee':
      switch (programType) {
        case 'mentoring':
          roleName = 'mentee';
          break;
        case 'coaching':
          roleName = 'coachee';
          break;
        case 'sponsoring':
          roleName = 'sponsee';
          break;
        default:
          roleName = 'trainee';
      }
      break;
    case 'guide':
      switch (programType) {
        case 'mentoring':
          roleName = 'mentor';
          break;
        case 'coaching':
          roleName = 'coach';
          break;
        case 'sponsoring':
          roleName = 'sponsor';
          break;
        default:
          roleName = 'guide';
      }
  }
  return roleName;
};
