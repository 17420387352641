// external
import React from 'react';
import { Field, Formik } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import {
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Link,
  Divider,
} from '@mui/material';

//internal
import firstLetterUppercase from 'utils/firstLetterUppercase';

// types
import { IUserField } from 'components/Users/UserDetails';

type IUserTextFieldProps = {
  field: IUserField;
  isMultiline: boolean;
  editing: boolean;
};

const UserTextField: React.FunctionComponent<IUserTextFieldProps> = ({
  field,
  isMultiline,
  editing,
}) => {
  const { label, value, validate, editable, options } = field;
  const fieldName = field.name;

  if (Array.isArray(value)) return <></>;

  const notUpperCaseFields = ['userId', 'email'];

  let isUrl = false;
  if (validate?.url) {
    isUrl = true;
  }

  const textFieldStyles = {
    width: '100%',
    label: {
      color: `#000000 !important`,
    },
  };

  const lineHeight = isMultiline ? '5.5rem' : '1.5rem';

  // validation

  const shouldValidate =
    validate?.url ||
    validate?.email ||
    validate?.alpha ||
    validate?.max ||
    validate?.required
      ? true
      : false;

  let validationSchema = yupString();

  validate?.url && (validationSchema = validationSchema.url('Not a valid url'));

  validate?.email &&
    (validationSchema = validationSchema.email('Not a valid email address'));

  validate?.alpha &&
    (validationSchema = validationSchema.matches(
      /^[a-zA-Z\s]+$/, // REGEX
      'Letters and spaces only'
    ));

  validate?.max &&
    (validationSchema = validationSchema.max(
      validate.max,
      `Value shouldn't be longer than ${validate.max} characters`
    ));

  validate?.required &&
    (validationSchema = validationSchema.required(
      'This field cannot be empty'
    ));

  const renderReadText = (fieldValue: string) => {
    let text = '';
    if (fieldValue === '') {
      text = 'N/A';
    } else if (notUpperCaseFields.includes(fieldName) || isUrl) {
      text = fieldValue;
    } else {
      text = firstLetterUppercase(fieldValue);
    }

    if (!isUrl) {
      return (
        <Typography
          variant="body1"
          sx={{
            color: '#0000008a',
            overflowY: 'scroll',
            height: lineHeight,
          }}
        >
          {text}
        </Typography>
      );
    } else if (fieldValue !== '') {
      return (
        <Box sx={{ overflowY: 'scroll', height: lineHeight }}>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href={value}
            sx={{
              color: 'info.dark',
            }}
          >
            {text}
          </Link>
        </Box>
      );
    } else {
      return (
        <Typography
          variant="body1"
          sx={{
            color: '#0000008a',
            overflowY: 'scroll',
            height: lineHeight,
          }}
        >
          N/A
        </Typography>
      );
    }
  };

  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{ [fieldName]: value }}
        validationSchema={yupObject({
          [fieldName]: validationSchema,
        })}
        onSubmit={() => {
          // do nothing
        }}
      >
        {({ errors, touched, values }) => (
          <>
            {editing && editable ? (
              <Field
                id={`${fieldName}-text-field`}
                name={fieldName}
                as={TextField}
                variant="outlined"
                label={label}
                sx={textFieldStyles}
                multiline={isMultiline}
                rows={isMultiline ? 4.5 : 1}
                select={options ? true : false}
                error={
                  shouldValidate &&
                  Boolean(errors[fieldName]) &&
                  Boolean(touched[fieldName])
                }
                helperText={
                  shouldValidate &&
                  Boolean(touched[fieldName]) &&
                  errors[fieldName]
                }
              >
                {options &&
                  options.map((option) => (
                    <MenuItem
                      key={`option-${option.value}`}
                      value={option.value}
                    >
                      {(option.label && firstLetterUppercase(option.label)) ||
                        (option.text && firstLetterUppercase(option.text))}
                    </MenuItem>
                  ))}
              </Field>
            ) : (
              <Box key={`${fieldName}-field`}>
                <Box sx={{ ml: 2, mb: 1.5 }}>
                  <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                    {label}
                  </Typography>
                  {renderReadText(values[fieldName])}
                </Box>
                <Divider />
              </Box>
            )}
          </>
        )}
      </Formik>
    </Grid>
  );
};

export default UserTextField;
