// external
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import {
  Box,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';

// internal
import countries, { ICountryData } from './countries';

// types
import { IUserField } from 'components/Users/UserDetails';

interface IUserCountryListProps {
  field: IUserField;
  editing: boolean;
}

const UserCountryList: React.FunctionComponent<IUserCountryListProps> = ({
  field,
  editing,
}) => {
  const defaultCountry = { code: 'ZZ', label: 'Not selected', phone: '999' };
  const { label, value, editable } = field;
  if (Array.isArray(value)) return <></>;

  const [country, setCountry] = useState<ICountryData>(
    countries.find(
      (item) => item.label.toLowerCase() === value.toLowerCase()
    ) || defaultCountry
  );
  const [inputValue, setInputValue] = useState('');

  const fieldName = field.name;

  const textFieldStyles = {
    label: {
      color: `#000000 !important`,
    },
  };

  return (
    <Grid item xs={6}>
      {editing && editable ? (
        <Autocomplete
          value={country}
          onChange={(event, newValue) => {
            setCountry(newValue || defaultCountry);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="country-select"
          disabled={!editing}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          // render options
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          // render input
          renderInput={(params) => (
            <Formik
              initialValues={{ [fieldName]: value }}
              validationSchema={object({
                [fieldName]: string().required('Predefined values only'),
              })}
              onSubmit={() => {
                // do nothing
              }}
            >
              {({ errors, touched }) => (
                <Field
                  {...params}
                  name={fieldName}
                  disabled={!editing || !editable}
                  as={TextField}
                  sx={textFieldStyles}
                  variant={editing && editable ? 'outlined' : 'standard'}
                  label={label}
                  error={
                    Boolean(errors[fieldName]) && Boolean(touched[fieldName])
                  }
                  helperText={Boolean(touched[fieldName]) && errors[fieldName]}
                />
              )}
            </Formik>
          )}
        />
      ) : (
        <>
          <Box sx={{ ml: 2, mb: 1.5 }}>
            <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
              {label}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#0000008a',
                height: '1.5rem',
              }}
            >
              {value === '' ? 'N/A' : value}
            </Typography>
          </Box>
          <Divider />
        </>
      )}
    </Grid>
  );
};

export default UserCountryList;
