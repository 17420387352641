//external
import { useState } from 'react';
import { Typography, Box, Avatar, Chip, Button, Divider } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { connect, ConnectedProps } from 'react-redux';

//internal
import firstLetterUppercase from 'utils/firstLetterUppercase';
import history from 'utils/history';
import { IProfileRelationship } from '../UserDetails';
import { useFunctions } from 'hooks/useFunctions';

//store
import { setAppSuccess, setAppError } from 'store/slices/appSlice';

//components

import DeleteDialogBox from 'components/DialogBox/DeleteDialogBox';

const mapState = () => {
  return {};
};

const mapDispatch = {
  setAppError,
  setAppSuccess,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IUserRelationshipsFieldProps extends PropsFromRedux {
  relationships: IProfileRelationship[];
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  oppositeRoleName: string | undefined;
}

const UserRelationshipsField: React.FunctionComponent<IUserRelationshipsFieldProps> =
  ({
    relationships,
    editing,
    setEditing,
    oppositeRoleName,
    setAppError,
    setAppSuccess,
  }) => {
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setDeleteDialogOpen] = useState(false);
    const [deleteRelationshipId, setDeleteRelationshipId] = useState('');

    const { deleteGuiderRelationship } = useFunctions();

    const goToProfile = (guiderUserId: string) => {
      history.push(`/users/${guiderUserId}`);
      setEditing(false);
    };

    const goToRelationship = (relationshipId: string) => {
      history.push(`/relationships/${relationshipId}`);
    };

    const handleRelationshipDelete = async () => {
      setLoading(true);
      try {
        await deleteGuiderRelationship(deleteRelationshipId);
        setAppSuccess(`Relationship was successfully deleted`);
      } catch (error) {
        let errorMessage =
          'Something went wrong, please try again later. If this issue persists please contact support';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        setAppError(errorMessage);
      }
      setLoading(false);
      setDeleteDialogOpen(false);
    };

    const handleDeleteDialogOpen = (relationshipId: string) => {
      setDeleteRelationshipId(relationshipId);
      setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
      setDeleteRelationshipId('');
      setDeleteDialogOpen(false);
    };

    return (
      <Box sx={{ mt: 2, width: '50%' }}>
        <Typography sx={{ opacity: 1, ml: 2 }} variant="body2">
          Relationships
        </Typography>
        {relationships.map((relationship, index) => {
          const initials = `${relationship.otherParty.firstName.charAt(
            0
          )}${relationship.otherParty.lastName.charAt(0)}`;
          const name = `${relationship.otherParty.firstName} ${relationship.otherParty.lastName}`;
          return (
            <Box key={`relationship-${index}`}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pt: 2.5,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 1,
                    mb: 3,
                    ml: 2,
                  }}
                >
                  <Avatar
                    alt={`${relationship.otherParty.firstName} ${relationship.otherParty.lastName}`}
                    src={relationship.otherParty.photo}
                    sx={{ width: 54, height: 54, bgcolor: 'secondary.dark' }}
                  >
                    {initials}
                  </Avatar>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      height: 60,
                      ml: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography>{name}</Typography>
                      <Chip
                        label={
                          oppositeRoleName &&
                          firstLetterUppercase(oppositeRoleName)
                        }
                        size="small"
                        color="default"
                        sx={{ ml: 1 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Button
                        color="info"
                        size="small"
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={() => goToProfile(relationship.otherParty.id)}
                      >
                        Profile
                      </Button>
                      <Button
                        color="info"
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          goToRelationship(relationship.id);
                        }}
                      >
                        Relationship activity
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <LoadingButton
                  aria-label="delete"
                  loading={loading}
                  disabled={!editing}
                  onClick={() => {
                    handleDeleteDialogOpen(relationship.id);
                  }}
                  sx={{
                    mr: 2,
                    color: 'rgba(0, 0, 0, 0.54);',
                    borderRadius: '50%',
                    height: 65,
                    width: 65,
                  }}
                >
                  <Delete sx={{ p: '' }} />
                </LoadingButton>
              </Box>
              <Divider />
            </Box>
          );
        })}
        {relationships.length === 0 && (
          <>
            <Typography sx={{ ml: 2, opacity: 0.54, my: 2 }}>
              <em>User has no relationships associated with this profile</em>
            </Typography>
            <Divider />
          </>
        )}
        <DeleteDialogBox
          handleDelete={handleRelationshipDelete}
          open={openDeleteDialog}
          loading={loading}
          handleClose={handleDeleteDialogClose}
          title="Delete relationship"
          description="Are you sure you want to delete this relationship? This will delete all data associated with it."
          deleteString="Delete relationship"
        />
      </Box>
    );
  };

export default connector(UserRelationshipsField);
