// external
import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  DrawerProps,
  Box,
  Typography,
  Chip,
  Link,
} from '@mui/material';
import { People, Person, Timeline, MenuBook } from '@mui/icons-material';

// assets
import colors from '../../styles/_colors.module.scss';
import guiderAdminLogoWhite from '../../assets/images/logo-guider-admin-white.svg';

interface NavigatorProps extends DrawerProps {
  handleMobileDrawerToggle?: () => void;
}
interface SideNavLink {
  name: string;
  icon: JSX.Element;
  externalLink?: string;
  internalLink?: string;
  handleMobileDrawerToggle?: () => void;
}

const { REACT_APP_VERSION } = process.env;

const iconSize = '32px';

const iconProps = {
  width: `calc(${iconSize} + 12px)`,
  height: iconSize,
  padding: '5px',
  margin: '5px 0',
};

const listItemProps = {
  py: '2px',
  px: 2,
  '&:hover, &:focus': {
    backgroundColor: 'rgba(92, 88, 244, 0.08)',
  },
};

const drawerWidth = 275;

const navLinks: SideNavLink[] = [
  {
    name: 'Programs',
    icon: <MenuBook sx={iconProps} />,
    internalLink: '/programs',
  },
  {
    name: 'Users',
    icon: <Person sx={iconProps} />,
    internalLink: '/users',
  },
  {
    name: 'Relationships',
    icon: <People sx={iconProps} />,
    internalLink: '/relationships',
  },
  {
    name: 'Metrics',
    icon: <Timeline sx={iconProps} />,
    internalLink: '/metrics',
  },
];

const NavigatorButton: React.FunctionComponent<SideNavLink> = ({
  externalLink,
  internalLink,
  name,
  icon,
  handleMobileDrawerToggle,
}) => {
  const location = useLocation();
  if (externalLink) {
    return (
      <ListItemButton
        component="a"
        sx={listItemProps}
        href={externalLink}
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleMobileDrawerToggle}
      >
        <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            variant: 'body1',
            fontSize: '0.9rem',
            fontWeight: 500,
          }}
        />
      </ListItemButton>
    );
  } else if (internalLink) {
    return (
      <ListItemButton
        component={RouterLink}
        sx={listItemProps}
        to={internalLink}
        selected={location.pathname === internalLink}
        onClick={handleMobileDrawerToggle}
      >
        <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            variant: 'body1',
            fontSize: '0.9rem',
            fontWeight: 500,
          }}
        />
      </ListItemButton>
    );
  } else {
    return (
      <ListItemButton sx={listItemProps} onClick={handleMobileDrawerToggle}>
        <ListItemIcon sx={{ color: 'inherit' }}>{icon}</ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            variant: 'body1',
            fontSize: '0.9rem',
            fontWeight: 500,
          }}
        />
      </ListItemButton>
    );
  }
};

const Navigator: React.FunctionComponent<NavigatorProps> = (navigatorProps) => {
  const { handleMobileDrawerToggle, ...other } = navigatorProps;

  return (
    <Drawer
      variant="permanent"
      open={true}
      onClose={handleMobileDrawerToggle}
      {...other}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <List disablePadding>
        <ListItem
          sx={{
            padding: '65px 30px',
            background: `radial-gradient(100% 381.69% at 100% 131.67%, #00E3FF 0%, #5C58F3 48.96%, #1E1B99 100%)`,
          }}
        >
          <img
            src={guiderAdminLogoWhite}
            alt="Guider Admin Logo"
            style={{ width: '400px' }}
          />
        </ListItem>
        <Divider />
        {navLinks.map((navLinkProps) => (
          <React.Fragment key={`navLink-${navLinkProps.name}`}>
            <ListItem disablePadding sx={{ color: `${colors.brandGray}` }}>
              <NavigatorButton
                handleMobileDrawerToggle={handleMobileDrawerToggle}
                {...navLinkProps}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.8rem',
          }}
        >
          <Typography sx={{ mr: 1, fontSize: '0.8rem' }}>
            {REACT_APP_VERSION}
          </Typography>
          <Chip label="Beta" size="small" sx={{ fontSize: '0.8rem' }} />
        </Box>
        <Typography sx={{ fontSize: '0.8rem', textAlign: 'center', my: 1 }}>
          {'Copyright © '}
          {`${new Date().getFullYear()} `}
          <Link
            rel="noopener noreferrer"
            color="inherit"
            href="https://guider-ai.com/"
          >
            Guider Global Ltd.
          </Link>
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Navigator;
