// external
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Field, Formik } from 'formik';
import { object as yupObject, string as yupString } from 'yup';

//internal
import firstLetterUppercase from 'utils/firstLetterUppercase';
import { useFunctions } from 'hooks/useFunctions';

// store
import { RootState } from 'store';
import { setAppError, setAppSuccess } from 'store/slices/appSlice';

const mapState = (state: RootState) => {
  return {
    app: state.app,
  };
};

const mapDispatch = {
  setAppError,
  setAppSuccess,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IRelationshipDialogBoxProps extends PropsFromRedux {
  open: boolean;
  setLoading: (boolean: boolean) => void;
  handleClose: () => void;
  userPrograms?: {
    trainee: string[];
    coach: string[];
  };
  userRole?: string;
  relationshipProgram?: string;
  oppositeRoleName?: string;
  userName: string;
  userEmail: string;
}

const RelationshipDialogBox: React.FunctionComponent<IRelationshipDialogBoxProps> =
  ({
    open,
    handleClose,
    userRole,
    relationshipProgram,
    oppositeRoleName,
    userName,
    userEmail,
    userPrograms,
    setAppError,
    setAppSuccess,
  }) => {
    const [loading, setLoading] = useState(false);

    const { createRelationship } = useFunctions();

    let isTrainee = false;
    let isCoach = false;

    if (userPrograms) {
      isTrainee = userPrograms?.trainee.length > 0;
      isCoach = userPrograms?.coach.length > 0;
    }

    const handleSubmiting = async (
      guideEmail: string,
      traineeEmail: string,
      programId: string
    ) => {
      setLoading(true);
      try {
        const relationshipId = await createRelationship(
          guideEmail.toLowerCase(),
          traineeEmail.toLowerCase(),
          programId
        );
        setAppSuccess(
          `Succesfully created relationship ${relationshipId} between ${guideEmail} and ${traineeEmail}}`
        );
        handleClose();
      } catch (error) {
        let errorMessage =
          'Something went wrong, please try again later. If this issue persists please contact support';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        setAppError(errorMessage);
      }
      setLoading(false);
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create relationship</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`Fill out the form below to create a new relationship for ${userName} (${userEmail})`}</DialogContentText>
        </DialogContent>
        <Formik
          initialValues={{
            program: relationshipProgram || '',
            role: userRole || '',
            otherPartyEmail: '',
          }}
          validationSchema={yupObject({
            otherPartyEmail: yupString().email('Not a valid email address'),
          })}
          onSubmit={(values) => {
            const programId = values.program.toLowerCase();

            let role = values.role.toLowerCase();
            if (
              role === 'mentor' ||
              role === 'coach' ||
              role === 'sponsor' ||
              role === 'guide'
            ) {
              role = 'guide';
            } else {
              role = 'trainee';
            }

            let traineeEmail;
            let guideEmail;
            if (role === 'trainee') {
              traineeEmail = userEmail;
              guideEmail = values.otherPartyEmail;
            } else {
              traineeEmail = values.otherPartyEmail;
              guideEmail = userEmail;
            }
            handleSubmiting(guideEmail, traineeEmail, programId);
          }}
        >
          {({ errors, touched, values, resetForm, handleSubmit }) => {
            const handleCancel = () => {
              resetForm();
              handleClose();
            };
            let oppositeEmailString = 'Email address';
            if (oppositeRoleName) {
              oppositeEmailString = `${firstLetterUppercase(
                oppositeRoleName
              )}'s email adress`;
            } else if (values.role === 'trainee') {
              oppositeEmailString = `Guide's email address`;
            } else if (values.role === 'guide') {
              oppositeEmailString = `Trainee's email address`;
            }

            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Field
                  sx={{ width: '90%', mb: 1 }}
                  id="relationship-role"
                  name="role"
                  as={TextField}
                  variant="outlined"
                  label="Role"
                  disabled={userRole}
                  select={!userRole}
                >
                  <MenuItem key="trainee-option" value="trainee">
                    Trainee
                  </MenuItem>
                  <MenuItem key="guide-option" value="guide">
                    Guide
                  </MenuItem>
                </Field>
                <Field
                  sx={{ width: '90%', mb: 1 }}
                  id="relationship-program"
                  name="program"
                  as={TextField}
                  variant="outlined"
                  label="Program"
                  disabled={relationshipProgram || values.role === ''}
                  select={!relationshipProgram}
                >
                  {values.role === 'trainee' &&
                    isTrainee &&
                    userPrograms?.trainee.map((program) => (
                      <MenuItem key={program} value={program}>
                        {firstLetterUppercase(program)}
                      </MenuItem>
                    ))}
                  {values.role === 'trainee' && !isTrainee && (
                    <MenuItem key="no-trainee-programs" value="">
                      <em>User has no Trainee profiles</em>
                    </MenuItem>
                  )}
                  {values.role === 'guide' &&
                    isCoach &&
                    userPrograms?.coach.map((program) => (
                      <MenuItem key={program} value={program}>
                        {firstLetterUppercase(program)}
                      </MenuItem>
                    ))}
                  {values.role === 'guide' && !isCoach && (
                    <MenuItem key="no-coach-programs" value="">
                      <em>User has no Guide profiles</em>
                    </MenuItem>
                  )}
                </Field>
                <Field
                  sx={{ width: '90%', mb: 1 }}
                  id="relationship-other-party-email"
                  name="otherPartyEmail"
                  as={TextField}
                  variant="outlined"
                  disabled={!relationshipProgram && values.program === ''}
                  label={oppositeEmailString}
                  error={
                    Boolean(errors.otherPartyEmail) &&
                    Boolean(touched.otherPartyEmail)
                  }
                  helperText={
                    Boolean(touched.otherPartyEmail) && errors.otherPartyEmail
                  }
                />
                <DialogActions>
                  <LoadingButton
                    onClick={handleCancel}
                    color="error"
                    disabled={loading}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    color="info"
                    onClick={() => {
                      handleSubmit();
                    }}
                    loading={loading}
                    disabled={
                      values.otherPartyEmail === '' ||
                      Boolean(errors.otherPartyEmail)
                    }
                  >
                    Create relationship
                  </LoadingButton>
                </DialogActions>
              </Box>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

export default connector(RelationshipDialogBox);
